.banner {
    width: 318px;
    height: 400px;
    min-width: 318px;

    display: flex;
    align-items: center;
    flex-direction: column;

    box-shadow: 0 0 6px #00000025;
    border-radius: 5px;

    position: relative;
    overflow: hidden;
    padding: 20px 0;
}

.banner .background {
    width: 100%;
    height: 100%;
    object-fit: cover;

    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
}

.banner .hover {
    width: 100%;
    height: 100%;
    display: flex;

    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;

    background: linear-gradient(175deg, transparent 0%, #0467AB15 50%, #0467AB30 100%);
}

.banner .logo {
    width: 50px;
    height: 50px;

    object-fit: contain;
    margin-bottom: 40px;
}

.banner p {
    text-shadow: 0 0 4px #00000044;
    font-family: 'Roboto', sans-serif;
    color: white;
}

.banner .title {
    font-weight: 600;
    font-size: 30px;
}

.banner .title::after {
    content: '';
    width: 100%;
    height: 2px;

    margin: 3px 0;
    display: flex;

    border-radius: 5px;
    background-color: #D41217;
}

.banner .subTitle {
    margin-bottom: 149px;
    font-weight: 600;
    font-size: 15px;
}

.banner .text {
    text-shadow: 0 0 3px #000000;
    text-align: center;
    padding: 0 10px;
    font-weight: 400;
    font-size: 16px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .banner {
        width: 100%;
        height: 300px;
    }

    .banner .logo {
        width: 60px;
        height: 60px;
        margin-bottom: 14px;
    }

    .banner .title {
        font-size: 28px;
    }
    
    .banner .title::after {
        height: 2px;
        margin: 3px 0 0;
    }
    
    .banner .subTitle {
        text-shadow: 0 0 3px #00000080;
        margin-bottom: 88px;
        font-weight: 500;
        font-size: 15px;
    }
    
    .banner .text {
        max-width: 600px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .banner {
        width: 100%;
        height: 440px;
        justify-content: space-between;
        border-radius: 0;
        padding: 20px 0;
    }
    
    .banner .logo {
        width: 60px;
        height: 60px;
    
        object-fit: contain;
        margin-bottom: 32px;
    }
    
    .banner .title {
        font-weight: 600;
        font-size: 30px;
    }
    
    .banner .title::after {
        margin: 3px 0 4px;
    }
    
    .banner .subTitle {
        margin-bottom: 188px;
        font-size: 16px;
    }
    
    .banner .text {
        text-shadow: 0 0 3px #000000;
        text-align: center;
        padding: 0 22px;
        font-weight: 400;
        font-size: 16px;
    }
}