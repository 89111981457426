.slider_product .slider_img {
    user-select: none;
    min-width: 220px;
    width: 220px;
    row-gap: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;
}

.slider_product .slider_img .view {
    width: 100%;
    height: 220px;
    overflow: hidden;
    border-radius: 4px;
}

.slider_product .slider_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.slider_product .slider_img .container {
    max-width: 100%;
    overflow-x: auto;

    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;

    display: flex;
    column-gap: 7px;
    padding-bottom: 10px;
}

.slider_product .slider_img .btn_preview {
    width: 75px;
    min-width: 75px;
    height: 50px;

    cursor: pointer;
    overflow: hidden;

    border-radius: 2px;
    border: solid 1.5px #44444450;
}

.slider_product .slider_img .container .actif {
    border: solid 2px #0467AB99;    
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 767px) {
    .slider_product .slider_img {
        width: 100%;
        row-gap: 24px;
        padding: 0 20px;
        margin-right: unset;
    }
}