.page_achievement {
    display: flex;
    flex-direction: column;
}

.page_achievement .presentation {
    display: flex;
    align-items: center;
    justify-content: center;
    
    column-gap: 120px;
    padding: 80px 0 100px;
    background-color: #0467ab59;
}

.page_achievement .presentation img {
    width: 400px;
    height: 480px;

    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.page_achievement .presentation .descrip {
    width: 100%;
    max-width: 570px;
    
    display: flex;
    flex-direction: column;
    
    padding-bottom: 10px;
    row-gap: 25px;
}

.page_achievement .presentation h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 34px;

    text-shadow: 0 0 3px #00000050;
    color: #fff;
}

.page_achievement .presentation p {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 19px;

    text-shadow: 0 0 3px #00000080;
    line-height: 1.6rem;
    color: #fff;
}

/* ------------------------------------------------------------------------------- */

.page_achievement .sect-achievement {
    width: 100%;
    max-width: 1440px;

    display: flex;
    flex-direction: column;
    
    row-gap: 50px;
    margin: 0 auto;
    padding: 60px 0 80px;
}

.page_achievement .filters {
    display: flex;
    flex-wrap: wrap;

    row-gap: 15px;
    column-gap: 5px;

    align-items: center;
    justify-content: center;
}

.page_achievement .filters span {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 22px;

    padding: 0 25px 0 15px;
    color: #444;
}

.page_achievement .filters button {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 20px;

    background-color: #44444410;
    color: #444444d9;
    padding: 4px 15px;
}

.page_achievement .filters button:hover {
    background-color: #0467ab19;
    color: #444444a0;
}

.page_achievement .filters .actif {
    background-color: #0467ab19;
}

/* ------------------------------------------------------------------------------- */

.page_achievement .main_achievement {
    width: 100%;
    row-gap: 30px;
    column-gap: 40px;
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.page_achievement .main_achievement li {
    padding: 8px;

    cursor: pointer;
    transition: .2s;

    display: flex;
    row-gap: 8px;
    flex-direction: column;

    box-shadow: 0 2px 6px #44444414;
    background-color: #fff;
    border-radius: 4px;
}

.page_achievement .main_achievement li:hover {
    opacity: .7;
}

.page_achievement .main_achievement li .container-img {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    background-color: #0467ab33;
    border-radius: 4px 4px 0 0;
}

.page_achievement .main_achievement li img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    object-fit: contain;
}

.page_achievement .main_achievement li p {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

/* ------------------------------------------------------------------------------- */

.page_achievement .pagination {
    display: flex;
    column-gap: 5px;
    align-items: flex-end;
    justify-content: center;
}

.page_achievement .pagination button {
    width: 30px;
    height: 30px;
    
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;

    border-radius: 15px;
    color: #444;
    opacity: .7;
}

.page_achievement .pagination .actif {
    background-color: #0467AB;
    color: #fff;
    opacity: .65;
}

.page_achievement .pagination span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;

    padding-bottom: 2px;
    color: #444;
    opacity: .8;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {    
    .page_achievement .presentation {
        column-gap: 50px;
        padding: 70px 40px 80px;
    }
    
    .page_achievement .presentation img {
        width: 330px;
    }
    
    .page_achievement .presentation .descrip {
        max-width: 510px;        
        padding-bottom: 0;
        row-gap: 14px;
    }
    
    .page_achievement .presentation h2 {
        font-size: 28px;
    }
    
    .page_achievement .presentation p {
        font-size: 16px;
        line-height: 1.4rem;
        color: #fff;
    }

    .page_achievement .sect-achievement {
        row-gap: 50px;
        padding: 50px 40px;
    }
    
    .page_achievement .filters {    
        row-gap: 12px;
    }
    
    .page_achievement .filters span {
        padding: 0 10px 0 15px;
        font-size: 18px;
    }
    
    .page_achievement .filters button {
        font-size: 16px;
    }

    .page_achievement .main_achievement {
        grid-template-columns: 1fr 1fr;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_achievement .presentation {
        flex-direction: column;
        padding: 0 0 60px;
        row-gap: 30px;
    }
    
    .page_achievement .presentation img {
        width: 100%;
        border-radius: 0;
    }
    
    .page_achievement .presentation .descrip {
        max-width: 510px;        
        padding: 0 25px;
        row-gap: 14px;
    }
    
    .page_achievement .presentation h2 {
        font-size: 30px;
    }
    
    .page_achievement .presentation p {
        font-size: 18px;
        text-shadow: 0 0 2px #00000090;        
    }

    .page_achievement .sect-achievement {
        row-gap: 40px;
        padding: 35px 25px;
    }

    .page_achievement .main_achievement {
        grid-template-columns: 1fr;
    }
}