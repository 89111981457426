.page_login {
    width: 100%;
    max-width: 1440px;
    user-select: none;

    display: flex;
    flex-direction: column;

    margin: 80px auto 130px;
    padding: 0 25px;
}

.page_login .title {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #222;
}

.page_login .title_comment {
    max-width: 455px;
    margin-bottom: 80px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #444;
}

.page_login .form_container {
    width: 100%;
    display: flex;
    column-gap: 100px;
    justify-content: space-between;
}

.form_login .input_signup {
    width: 100%;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_login {
        padding: 80px 100px 120px;
    }

    .page_login .form_container {
        flex-direction: column;
        row-gap: 80px;
    }

    .page_login .title_form {
        text-align: center;
    }

    .page_login .form_login,
    .page_login .form_signup,
    .page_login .form_login .btn_submit,
    .page_login .form_signup .btn_submit {
        margin: 0 auto;
    }

    .page_login .form_signup .btn_submit {
        margin: 0 auto;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_login {
        padding: 0 25px;
    }

    .page_login .form_signup .container_input {
        grid-template-columns: 1fr;
    }

    .page_login .form_signup .btn_submit {
        transform: unset;
        position: unset;
    }
    
    .page_login .item_invisible {
        display: none;
    }
}