*,
::after,
::before {
    margin: 0;
    padding: 0;

    outline: none;
    list-style: none;
    box-sizing: border-box;
}

body {
    background-color: #80808015;
    overflow-x: hidden;
    padding-top: 80px;
}

.page {
    min-height: calc(100vh - 375px);
}

button {
    background-color: transparent;
    cursor: pointer;
    border: none;
}

input,
select,
textarea {
    border: none;
    resize: none;
}

a {
    text-decoration: none;
    color: black;
}

.invisible,
.invisible:hover {
    visibility: hidden;
}