.page_category {
    display: flex;
    flex-direction: column;
}

.page_category .container_slider_banner {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 65px auto;

    display: flex;
    column-gap: 40px;
    justify-content: space-between;
}

.page_category .container_main_menu {
    width: 100%;
    row-gap: 15px;

    display: flex;
    align-items: center;
    flex-direction: column;
    
    background-color: #0467ab66;
    padding: 40px 25px 80px;
}

.page_category .container_main_menu h3 {
    width: 100%;
    max-width: 1290px;
    
    color: white;
    text-shadow: 0 0 2px #00000025;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;

}

.page_category .container_main_menu p {
    width: 100%;
    max-width: 1290px;
    
    padding-left: 3px;
    margin-bottom: 25px;

    color: white;
    text-shadow: 0 0 2px #00000025;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 18px;    
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format pc_small --------------------------- */

@media screen and (max-width: 1440px) {
    .page_category .container_slider_banner {
        padding: 0 45px;
    }

    .page_category .container_main_menu {
        padding: 40px 40px 80px;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {    
    .page_category .container_slider_banner {
        flex-direction: column;
        margin: 65px auto 0;
        padding: 0 30px;
        row-gap: 40px;
    }

    .page_category .container_main_menu {
        margin-top: 40px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_category {
        row-gap: 0;
        padding: 0;
        margin: 0;
    }
    
    .page_category .container_slider_banner {
        row-gap: 0;
        padding: 0;
        margin: 0;
    }

    .page_category .container_main_menu {
        padding: 20px 20px 70px;
        margin-top: 0;
    }

    .page_category .container_main_menu h3 {
        font-weight: 600;
        font-size: 26px;
        padding: 0 4px;
    
    }
    
    .page_category .container_main_menu p {
        text-shadow: 0 0 2px #00000030;
        font-size: 17px;    
    }
}