.form_signup {
    width: 100%;
    max-width: 772px;

    display: flex;
    row-gap: 25px;
    flex-direction: column;
}

.form_signup .title_form {
    margin-bottom: 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #333;
}

.form_signup .container_input {
    display: grid;
    row-gap: 25px;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
}

.form_signup .container_opt_msg {
    display: flex;
    row-gap: 25px;
    flex-direction: column;
    align-items: flex-start;
}

.form_signup .btn_check {
    font-weight: 500;
}

.form_signup .alert {
    animation: alert .8s 0s 3 ease-in-out;
}

.form_signup .alert i {
    font-size: 12px;
}

@keyframes alert {
    0% {
        color: #808080;
    }

    25% {
        color: #d41217dd;
    }

    50% {
        color: #808080;
    }

    75% {
        color: #d41217dd;
    }

    100% {
        color: #808080;
    }
}

.form_signup .msg_error {
    display: flex;
    column-gap: 5px;
    align-items: center;

    color: #d41217dd;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 15px;
}

.form_signup .msg_error i {
    font-size: 15px;
    opacity: .9;
}

.form_signup .msg_optional {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #444;
    opacity: .8;
}

.form_signup .btn_submit {
    display: flex;
    justify-self: flex-start;

    color: #0467AB;
    padding: 8px 28px;
    transition: all .2s;

    border-radius: 3px;
    border: solid 1px #0467AB;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.form_signup .btn_submit:hover {
    background-color: #0467AB;
    color: #fff;
    opacity: .7;
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .form_signup {
        row-gap: 35px;
    }

    .form_signup .title_form {
        margin-bottom: 0;
    }
}