.lock_page {
    width: 100%;
    height: 100vh;

    display: flex;
    background-color: #80808015;
    
    position: fixed;
    z-index: 50;

    left: 0;
    top: 0;
}