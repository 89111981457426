.mdl_forget_pw {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0467AB45;
    padding-bottom: 150px;

    position: fixed;
    z-index: 999;

    left: 0;
    top: 0;
}

.mdl_forget_pw .content {
    width: 750px;

    display: flex;
    align-items: center;
    flex-direction: column;

    row-gap: 20px;
    padding: 32px 50px 40px;

    box-shadow: 0 0 8px #00000025;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.mdl_forget_pw .title_modal {
    color: #0467ABee;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 30px;
}

.mdl_forget_pw .text {
    max-width: 560px;
    color: #444;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 17px;
}

.mdl_forget_pw .input_forget_pw {
    width: 100%;
    max-width: 400px;

    margin: 15px auto;
}

.mdl_forget_pw .msg_error_conf {
    display: flex;
    column-gap: 4px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #D41217;
}

.mdl_forget_pw .msg_resend_success {
    display: flex;
    column-gap: 4px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #0467AB;
}

.mdl_forget_pw .msg_resend_success i {
    opacity: .8;
    font-size: 18px;
    font-weight: 600;
}

.mdl_forget_pw .btn_submit {
    display: flex;
    justify-self: flex-start;

    color: #0467AB;
    transition: all .2s;

    border-radius: 3px;
    border: solid 1px #0467AB;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;

    padding: 6px 28px;
    margin: 5px 0;
}

.mdl_forget_pw .btn_submit:hover {
    background-color: #0467AB;
    color: #fff;
    opacity: .7;
}

.mdl_forget_pw .btn_resend_code {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;

    text-decoration: underline;
    transition: all .2s;
    color: #808080cc;
}

.mdl_forget_pw .btn_resend_code:hover {
    color: #D41217;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .mdl_forget_pw .content {
        width: 650px;
    }

    .mdl_forget_pw .text {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
    }

    .mdl_forget_pw .btn_submit {
        padding: 7px 24px 6px;
        margin: 0;

        font-size: 16px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .mdl_forget_pw {
        padding-bottom: 50px;
    }

    .mdl_forget_pw .content {
        width: 350px;
        padding: 32px 20px 40px;
    }

    .mdl_forget_pw .title_modal {
        white-space: nowrap;
        color: #0467ABcc;
        font-weight: 500;
        font-size: 24px;
    }

    .mdl_forget_pw .text {
        max-width: 400px;
        font-size: 13.5px;
        color: #444;
    }

    .mdl_forget_pw .input_forget_pw {
        width: 100%;
        max-width: 300px;
    
        margin: 15px auto 10px;
    }

    .mdl_forget_pw .input_code {
        margin: 15px auto;
    }
}