footer {
    width: 100%;
    height: 295px;

    user-select: none;

    background-color: white;
    box-shadow: 0 0 8px #00000016;
}

footer .content {
    width: 100%;
    height: 100%;
    max-width: 1440px;

    display: flex;
    margin: 0 auto;
    position: relative;
}

footer .footer_menu {
    width: calc(100% - 400px);
    position: relative;
    display: flex;
}

footer .footer_menu ul {
    width: 30%;
    row-gap: 8px;

    display: flex;
    flex-direction: column;

    padding: 25px;
}

footer .footer_menu h4 {
    margin-bottom: 4px;
    white-space: nowrap;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #444;
}

footer .footer_menu button {
    white-space: nowrap;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #444;
}

footer .footer_menu button:hover {
    color: #d41217;
}

footer .footer_menu .btn_home {
    position: absolute;
    bottom: 35px;
    left: 22px;
}

footer .footer_menu .btn_home:hover {
    opacity: 1;
}

footer .footer_location {
    width: 400px;
    padding: 40px;

    display: flex;
    row-gap: 15px;
    flex-direction: column;

    position: relative;
    background-color: #0467ab80;
}

footer .footer_location h4 {
    text-shadow: 0 0 2px #00000016;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
}

footer .footer_location ul {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
}

footer .footer_location li {
    display: flex;
    column-gap: 15px;
    align-items: center;

    position: relative;
    z-index: 1;
}

footer .footer_location li img {
    height: 30px;
}

footer .footer_location li:first-child img {
    position: relative;
    top: -16px;
}

footer .footer_location li p {
    text-shadow: 0 0 2px #00000016;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

footer .footer_location li:first-child p {
    height: unset;
}

footer .footer_location .back_icon {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

footer .license {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;

    white-space: nowrap;
    text-align: center;
    color: #444;

    transform: translateX(-50%);
    position: absolute;

    bottom: 24px;
    left: 50%;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    footer {
        height: unset;
    }

    footer .content {
        flex-direction: column-reverse;
    }

    footer .footer_location {
        width: 100%;
        padding: 40px 60px;

        display: flex;
        row-gap: 15px;
        flex-direction: column;

        position: relative;
        background-color: #0467ab80;
    }

    footer .footer_menu {
        width: 100%;
        height: unset;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 20px 40px 80px;
    }

    footer .footer_menu ul {
        width: 50%;
    }

    footer .footer_menu .btn_home {
        transform: scale(1.2);
        bottom: 105px;
        left: 80px;
    }

    footer .license {
        bottom: 32px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    footer .footer_location {
        padding: 40px;
    }

    footer .footer_menu {
        padding: 10px 30px 180px;
    }

    footer .footer_menu ul {
        width: 100%;
    }

    footer .footer_menu .btn_home {
        bottom: 95px;
        left: 75px;
    }

    footer .license {
        bottom: 35px;
    }
}