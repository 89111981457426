.slider_service {
    width: 100%;

    display: flex;
    row-gap: 20px;
    flex-direction: column;

    margin-bottom: 100px;
    position: relative;
}

.slider_service .border_view {
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 0 4px #808080a1;
}

.slider_service .view {
    width: 100%;
    height: 320px;
    overflow: hidden;
    background-color: #0467ab15;
    box-shadow: inset 0 0 4px #808080a1;
}

.slider_service .view img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider_service .arrow {
    width: 30px;
    height: 50px;

    display: flex;
    justify-content: center;

    background-color: #808080d0;
    color: #fff;

    transition: all .2s;
    padding: 3px;

    font-family: 'Dosis', sans-serif;
    font-weight: 700;
    font-size: 30px;

    transform: translateY(-50%);
    position: absolute;
    top: 45%;
}

.slider_service .arrow:hover {
    background-color: #0467ab88;
}

.slider_service .arrow_left {
    left: -30px;
    padding-left: 8px;
    border-radius: 60px 0 0 60px;
}

.slider_service .arrow_right {
    right: -30px;
    padding-right: 8px;
    border-radius: 0 60px 60px 0;
}

.slider_service .dots {
    display: flex;
    column-gap: 12px;
    justify-content: center;
}

.slider_service .dot {
    width: 12px;
    height: 12px;

    display: flex;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 2px #808080a1;
}

.slider_service .dot_actif {
    background-color: #0467ab88;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .slider_service {
        margin-bottom: 0;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .slider_service .border_view {
        padding: 10px;
    }

    .slider_service .view {
        height: 200px;
    }
}