.mdl_achievement {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0467AB40;

    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
}

.mdl_achievement .content {
    width: 700px;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px;
}

.mdl_achievement .slider_achievement {
    height: 295px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    box-shadow: inset 0 0 6px #00000010;
    background-color: #0467AB40;
    position: relative;
    overflow: hidden;
}

.mdl_achievement .slider_achievement img {
    width: 45%;
    height: 80%;
    object-fit: contain;
}

.mdl_achievement .slider_achievement .arrow {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;

    transition: all .2s;
    user-select: none;
    color: #ffffff;
    cursor: pointer;

    transform: translateY(-50%);
    position: absolute;
    top: 45%;
}

.mdl_achievement .slider_achievement .arrow:hover {
    opacity: .5;
}

.mdl_achievement .slider_achievement .arrow_left {
    left: 20px;
}

.mdl_achievement .slider_achievement .arrow_right {
    right: 20px;
}

.mdl_achievement .descrip {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    padding: 10px 15px;
}

.mdl_achievement .descrip h2 {
    margin-top: 5px;
    color: #0467AB;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 24px;
}

.mdl_achievement .descrip p {
    max-height: 220px;
    overflow-y: auto;

    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #444;
}

.mdl_achievement button {
    display: flex;
    transition: all .25s;
    align-self: flex-start;
    
    color: #D41217;
    border-radius: 3px;
    border: solid 1px #D41217;
    
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;

    padding: 6px 20px;
    margin: 10px auto;
    opacity: .7;
}

.mdl_achievement button:hover {
    background-color: #D41217;
    color: #fff;
    opacity: .5;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .mdl_achievement .descrip p {
        max-height: 320px;
    }    
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 750px) {
    .mdl_achievement .content {
        margin: 0 25px;
        background-color: #fff;
        border-radius: 6px;
        padding: 5px;
    }

    .mdl_achievement .slider_achievement {
        height: 260px;
    }

    .mdl_achievement .slider_achievement img {
        width: 65%;
        height: 80%;
    }

    .mdl_achievement .slider_achievement .arrow {
        opacity: .6;
    }

    .mdl_achievement .slider_achievement .arrow_left {
        left: 10px;
    }
    
    .mdl_achievement .slider_achievement .arrow_right {
        right: 10px;
    }
}