.input_login,
.input_signup,
.input_forget_pw,
.input_contact,
.input_settings {
    width: 100%;
    row-gap: 8px;

    display: flex;
    flex-direction: column;

    position: relative;
}

.input_login label,
.input_signup label,
.input_forget_pw label,
.input_contact label,
.input_settings label {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #444;
}

.input_login input,
.input_signup input,
.input_forget_pw input,
.input_contact input,
.input_settings input {
    padding: 10px 16px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px #0000001a;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #444;
}

.input_contact input {
    box-shadow: 0 0 5px #00000005;
}

.input_login .alert_field,
.input_signup .alert_field,
.input_forget_pw .alert_field,
.input_contact .alert_field,
.input_settings .alert_field,
.input_request .alert_field {
    border: solid 1px #80808050;
    filter: invert(5%) sepia(2000%) saturate(250%) hue-rotate(300deg) brightness(100%) contrast(100%);
    animation: alert_field .5s 0s 4 linear forwards;
}

@keyframes alert_field {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .25;
    }
    100% {
        opacity: 1;
    }
}

.input_login input:focus,
.input_signup input:focus,
.input_forget_pw input:focus,
.input_contact input:focus,
.input_settings input:focus {
    box-shadow: 0 0 5px #0467ABb0;
    color: #0467ABb0;
    font-weight: 700;
}

.input_login input:focus::placeholder,
.input_signup input:focus::placeholder,
.input_forget_pw input:focus::placeholder,
.input_contact input:focus::placeholder,
.input_settings input:focus::placeholder {
    color: #0467ABb0;
}

.input_login button,
.input_signup button,
.input_forget_pw button,
.input_contact button,
.input_settings button {
    font-size: 16px;
    color: #444444e0;

    position: absolute;
    bottom: 9px;
    right: 16px;
}

.input_login button:hover,
.input_signup button:hover,
.input_forget_pw button:hover,
.input_contact button:hover,
.input_settings button:hover {
    color: #0467AB;
    opacity: .7;
}