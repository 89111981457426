.loader {
    position: fixed;
    z-index: 9999;

    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f0f0f0;
}

.loader .btn_home {
    position: relative;
    opacity: .8;
    z-index: 1;

    margin: 0 100px 100px 0;
    transform: scale(3);
    column-gap: 10px;
}

.loader .btn_home:hover {
    opacity: 1;
}

.loader .btn_home h1 {
    align-items: center;
    row-gap: 2px;
}

.loader .btn_home span { 
    font-weight: 400;
    opacity: .8;
}

.loader .shadow {
    transform: rotate(10deg);
    position: absolute;
    z-index: 0;

    left: -750px;

    width: 500px;
    height: 500vh;

    display: flex;

    /* background: linear-gradient(90deg, transparent 0%, #00000010 45%, #00000010 55%, transparent 100%); */
    background: linear-gradient(90deg, transparent 0%, #0467ab30 45%, #0467ab30 55%, transparent 100%);
    animation: loader 1.75s 0s infinite ease-in-out;
}

@keyframes loader {
    0% {
        
    }
    100% {
        transform: rotate(12deg) translateX(2500px);
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .loader .btn_home {    
        margin: 0 50px 100px 0;
        transform: scale(2.8);
    }
    
    .loader .btn_home h1 {
        row-gap: 4px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .loader .btn_home {    
        margin: 0 0 150px 0;
        transform: scale(1.75);
        flex-direction: column;
        row-gap: 18px;
    }

    .loader .btn_home img {
        object-fit: contain;
    }
}