.page_product {
    display: flex;
    flex-direction: column;
    position: relative;
}

.page_product .container_slider_banner {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 65px auto 35px;

    display: flex;
    column-gap: 40px;
    justify-content: space-between;
}

/* ------------------------------------------------------------------- */

.page_product .features_and_benefits {
    width: 100%;
    padding: 40px 0 60px;
    background-color: #0467AB66;
}

.page_product .features_and_benefits .content {
    width: 100%;
    max-width: 1290px;
    
    display: grid;
    column-gap: 80px;
    grid-template-columns: 1fr 1fr;
    
    padding: 0 75px;
    margin: 0 auto;
}

.page_product .features_and_benefits ul {
    width: 100%;

    display: flex;
    flex-direction: column;
    
    row-gap: 8px;
    padding: 20px 40px 35px;

    box-shadow: 0 0 8px #00000025;
    background-color: white;
}

.page_product .features_and_benefits li {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.page_product .features_and_benefits .features li::before {
    content: '-';
}

.page_product .features_and_benefits .benefits li::before {
    content: '+';
    font-weight: 800;
}

.page_product .features_and_benefits li:first-child:before {
    content: unset;
}

.page_product .features_and_benefits h4 {
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #444;
    opacity: .9;
}

.page_product .features_and_benefits p {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;    
}

/* ------------------------------------------------------------------- */

.page_product .dashboard {
    width: 100%;
    max-width: 1290px;
    margin: 35px auto 0;
    padding-bottom: 30px;
}

.page_product .dashboard .onglets {
    display: flex;
    column-gap: 15px;
    justify-content: center;
    padding-bottom: 20px;
}

.page_product .dashboard .onglets button {
    padding: 6px 50px 4px;
    transition: all .2s;

    color: #808080c0;
    border-radius: 15px;
    border: solid 2px #00000000;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.page_product .dashboard .onglets button:hover {
    color: #808080d0;
    border: solid 2px #0467AB90;
    box-shadow: 0 0 4px #00000005;
    background-color: #ffffffb0;
}

.page_product .dashboard .onglets .actif {
    color: #fff;
    border: solid 2px #00000005;
    box-shadow: 0 0 4px #00000005;
    background-color: #0467AB90;
    /* background: radial-gradient(#0467AB90 45%, #0467AB35 89%, transparent 100%); */
}

.page_product .sect_descrip {
    display: flex;

    border-radius: 8px;
    border: solid 4px #0467AB90;
    box-shadow: 0 0 8px #00000020;

    background-color: #fff;
    padding: 40px;
}

.page_product .sect_descrip .container_descrip {
    max-width: 80%;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
}

.page_product .sect_descrip h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #444;
}

.page_product .sect_descrip .descrip {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #444; 
}

/* ------------------------------------------------------------------------------- */

.page_product .contact {
    width: 100%;
    max-width: 1440px;

    padding: 0 100px;
    margin: 80px auto 140px;

    display: flex;
    justify-content: space-between;
}

.page_product .contact p {
    width: 100%;
    max-width: 520px;

    display: flex;
    column-gap: 40px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #808080;
}

.page_product .contact p img {
    width: 50px;
    object-fit: contain;
}

.page_product .contact .container_btn_contact {
    display: flex;
    column-gap: 50px;
}

.page_product .contact button,
.page_product .contact a {
    height: 55px;
    padding: 0 50px;
    border-radius: 50px;

    display: flex;
    column-gap: 15px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.page_product .contact button img,
.page_product .contact a img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.page_product .contact .btn-phone {
    box-shadow: 0 0 6px #0467ab66;
    background-color: white;
    color: #0467abb3;
}

.page_product .contact .btn-phone img {
    filter: invert(60%) sepia(98%) saturate(932%) hue-rotate(176deg) brightness(74%) contrast(102%);
}

.page_product .contact .btn-phone:hover {
    background-color: #0467aba3;
    color: #ffffffd0;
}

.page_product .contact .btn-phone:hover img {
    filter: invert(0%) sepia(100%) saturate(2%) hue-rotate(122deg) brightness(153%) contrast(151%);
}

.page_product .contact .btn-email {
    background-color: #0467ab99;
    color: white;
}

.page_product .contact .btn-email:hover {
    box-shadow: 0 0 6px #0467ab66;
    background-color: white;
    color: #0467abb3;
}

.page_product .contact .btn-email:hover img {
    filter: invert(28%) sepia(98%) saturate(932%) hue-rotate(176deg) brightness(74%) contrast(102%);
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format pc_small --------------------------- */

@media screen and (max-width: 1440px) {
    .page_product .container_slider_banner {
        padding: 0 45px;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {    
    .page_product .container_slider_banner {
        flex-direction: column;
        margin: 65px auto 0;
        padding: 0 30px;
        row-gap: 40px;
    }

    .page_product .features_and_benefits {
        margin-top: 40px;
    }

    .page_product .features_and_benefits .content {
        padding: 0 30px;
        column-gap: 20px;
    }

    .page_product .features_and_benefits ul {
        padding: 18px 20px 25px;
        row-gap: 7px;
    }

    .page_product .features_and_benefits p {
        font-size: 15px;
    }

    /* ------------------------------------------------------------------- */
    
    .page_product .dashboard {
        width: 100%;
        max-width: calc(100% - 40px);
        margin: 35px auto 0;
        padding-bottom: 20px;
    }
    
    .page_product .dashboard .onglets {
        column-gap: 12px;
    }
    
    .page_product .dashboard .onglets button {
        padding: 6px 18px 5px;
        border-radius: 5px;
        font-size: 14px;
    }
    
    .page_product .sect_descrip {
        border-radius: 6px;
        padding: 23px 35px 30px;
        border: solid 3px #0467AB90;
    }
    
    .page_product .sect_descrip h4 {
        font-size: 20px;
    }
    
    .page_product .sect_descrip .descrip {
        font-size: 16px;
    }

    /* ------------------------------------------------------------------------------- */
    
    .page_product .contact {
        margin: 50px auto 100px;
        flex-direction: column;
    }
    
    .page_product .contact p {
        width: unset;
        font-size: 18px;
        column-gap: 20px;
        margin: 0 auto 30px;
    }
    
    .page_product .contact p img {
        width: 45px;
        margin-left: 30px;
        object-fit: contain;
    }
    
    .page_product .contact .container_btn_contact {
        display: flex;
        column-gap: 50px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_product {
        row-gap: 0;
        padding: 0;
        margin: 0;
    }
    
    .page_product .container_slider_banner {
        row-gap: 0;
        padding: 0;
        margin: 0 0 40px;
    }

    .page_product .features_and_benefits {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .page_product .features_and_benefits .content {
        max-width: 500px;
        grid-template-columns: 1fr;
        padding: 0 20px;
        row-gap: 40px;
    }

    /* ------------------------------------------------------------------------------- */

    .page_product .sect_mobile {
        margin: 0 20px;
    }

    .page_product .sect_mobile .btn_open_sect_mobile {
        width: 100%;
        height: 70px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 20px;

        border-bottom: 1px solid #80808099;
        padding-right: 5px;
        color: #444444d1;
    }
    
    .page_product .sect_mobile .btn_open_sect_mobile span {
        font-weight: 300;
        font-size: 30px;
    }
    
    .page_product .sect_mobile .btn_open_sect_mobile .large {
        font-size: 60px;
        font-weight: 200;
        margin-bottom: 4px;
    }
    
    .page_product .sect_mobile .descrip {
        padding: 25px 0 50px;
        color: #444;

        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    /* ------------------------------------------------------------------------------- */

    .page_product .contact {    
        row-gap: 35px;
        flex-direction: column;
        
        padding: 0 20px;
        margin: 70px auto 90px;
    }
    
    .page_product .contact p {
        column-gap: 20px;
        font-size: 16px;
    }
    
    .page_product .contact p img {
        width: unset;
        height: 100%;
        margin: 0 10px;
    }
    
    .page_product .contact .container_btn_contact {
        flex-direction: column;
        row-gap: 25px;
    }
    
    .page_product .contact button,
    .page_product .contact a {
        width: 100%;
        
        justify-content: center;
        column-gap: 10px;

        margin: 0 auto;
        padding: 0;
    }
    
    .page_product .contact button img,
    .page_product .contact a img {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }
}