.page_about {
    max-width: 1440px;
    width: 100%;

    display: flex;
    flex-direction: column;

    margin: 65px auto 80px;
    padding: 0 100px;
}

.page_about h2 {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #444;
}

.page_about .subTitle {
    margin-bottom: 50px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #444;
}

.page_about .rule_first {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    margin-bottom: 50px;
}

.page_about .rule_first h3 {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #444;
}

.page_about .rule_first li {
    display: flex;
    column-gap: 5px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #444;
}

.page_about .our_skills,
.page_about .our_stuffs {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    margin-bottom: 50px;
}

.page_about .our_skills h3,
.page_about .our_stuffs h3 {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #444;
}

.page_about .our_skills li,
.page_about .our_stuffs li {
    line-height: 1.5rem;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #444;
}

.page_about .our_skills span,
.page_about .our_stuffs span {
    white-space: nowrap;
    font-weight: 800;
    color: #000;
    opacity: .9;
}

.page_about .our_skills p,
.page_about .our_stuffs p {
    margin-left: 25px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #444;
}

.page_about .our_stuffs img {
    height: 250px;
    width: 400px;
    object-fit: contain;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_about {
        margin: 80px auto 60px;
        padding: 0 50px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_about {
        row-gap: 40px;
        margin: 0 auto;
        padding: 40px 25px 60px;
    }

    .page_about h2 {
        font-size: 28px;
        margin-bottom: 0;
    }

    .page_about .subTitle {
        margin-bottom: 0;
    }

    .page_about .our_skills,
    .page_about .our_stuffs,
    .page_about .rule_first {
        margin-bottom: 0;
    }

    .page_about .our_stuffs img {
        height: unset;
        width: 100%;
        object-fit: contain;
    }
}