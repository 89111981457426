.mdl_request_quote {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0467AB45;

    position: fixed;
    z-index: 999;

    left: 0;
    top: 0;
}

.mdl_request_quote .content {
    width: 750px;
    height: 100%;
    max-height: 780px;

    display: flex;
    flex-direction: column;

    row-gap: 24px;
    padding: 35px 15px 30px 20px;

    box-shadow: 0 0 8px #00000025;
    background-color: #f9f9f9;
    border-radius: 8px;

    position: relative;
}

.mdl_request_quote .btn_close_mdl {
    width: 30px;
    height: 30px;
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2px 0 0 1px;

    transition: all .2s;
    position: absolute;
    right: 18px;
    top: 20px;
}

.mdl_request_quote .btn_close_mdl:hover {
    opacity: .75;
    background-color: #D41217;
    box-shadow: 0 0 2px #44444435;
}

.mdl_request_quote .btn_close_mdl i {
    font-size: 20px;
    color: #808080;
    transition: all .2s;
}

.mdl_request_quote .btn_close_mdl:hover i {
    color: #fff;
}

.mdl_request_quote .form {
    height: 100%;
    max-height: 100%;

    display: flex;
    row-gap: 20px;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px 25px 25px 20px;

    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;
}

.mdl_request_quote h3 {
    color: #0467abc0;
    text-align: center;

    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 30px;
}

.mdl_request_quote h4 {
    display: flex;
    column-gap: 7px;
    align-items: center;

    border-bottom: 2px solid #444444b1;
    color: #444444d1;

    padding-bottom: 4px;
    margin-top: 15px;
    opacity: .95;

    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.mdl_request_quote .container_inp {
    display: grid;
    align-items: flex-end;

    row-gap: 20px;
    column-gap: 15px;

    grid-template-columns: 1fr 1fr;
}

.mdl_request_quote label {
    color: #444444;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.mdl_request_quote .container_inp .input_settings input {
    box-shadow: 0 0 4px #0000001a;
}

.mdl_request_quote .container_inp .lock input {
    background-color: #EEEEEE;
}

.mdl_request_quote .container_inp .lock input:focus {
    box-shadow: 0 0 5px #0000001a;
    font-weight: 600;
    color: #444;
}

.mdl_request_quote .address_selector {
    width: 100%;

    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.mdl_request_quote .address_selector select {
    padding: 10px 16px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px #0000001a;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #444;
}

.mdl_request_quote .input_request {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.mdl_request_quote .input_request textarea {
    height: 133px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;

    padding: 10px 16px;
    border-radius: 3px;
    background-color: #fff;

    box-shadow: 0 0 5px #0000001a;
    color: #444;
}

.mdl_request_quote .msg_error {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;

    text-align: center;
    color: #D41217;
}

.mdl_request_quote .btn_ask_quote {
    display: flex;
    transition: all .25s;
    align-self: flex-start;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;

    color: #D41217;
    border-radius: 3px;
    border: solid 1px #D41217;

    margin: 20px auto 0;
    padding: 7px 16px;
    opacity: .75;
}

.mdl_request_quote .btn_ask_quote:hover {
    background-color: #D41217;
    color: #fff;
    opacity: .6;
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .mdl_request_quote .content {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        max-height: unset;
    
        row-gap: 14px;
        border-radius: 5px;
        padding: 25px 3px 20px 10px;
    }

    .mdl_request_quote .btn_close_mdl {
        right: 10px;
        top: 14px;
    }

    .mdl_request_quote h3 {
        font-size: 26px;
    }

    .mdl_request_quote .form {
        row-gap: 16px;
        padding: 5px 15px 25px 10px;
    }

    .mdl_request_quote .container_inp {
        row-gap: 10px;
        grid-template-columns: 1fr;
    }
}