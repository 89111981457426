.input_code {
    width: 280px;
    height: 36px;

    display: flex;
    column-gap: 10px;

    border-radius: 8px;
    position: relative;
    margin: 25px 0;
}

.input_code .box {
    width: 25px;
    height: 100%;

    display: flex;
    border-radius: 8px;

    box-shadow: 0 0 8px #80808020;
    border: solid 1px #80808080;
    background-color: white;
}

.input_code .actif {
    box-shadow: 0 0 8px #0467AB20;
    border: solid 1px #0467AB80;    
}

.input_code input {
    width: 125%;
    padding: 5px 8px;
    border-radius: 8px;
    letter-spacing: 24px;

    background-color: transparent;

    font-family: 'Fira Code', sans-serif;
    font-weight: 700;
    font-size: 18px;

    direction: rtl;
    text-align: left;
    caret-color: transparent;

    position: absolute;
    left: 0;
    top: 0;
}