.page_service {
    display: flex;
    flex-direction: column;
}

.page_service .container_video_banner {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 65px auto 65px;

    display: flex;
    column-gap: 40px;
    justify-content: space-between;
}

.page_service .player {
    width: 100%;
    max-width: 850px;
    height: 400px;
}

.page_service .container_slider_descrip {
    width: 100%;

    display: flex;
    justify-content: center;

    padding: 55px 0 65px;
    background-color: #0467AB55;
}

.page_service .container_slider_descrip .content {
    width: 100%;
    max-width: 1440px;

    display: grid;
    grid-template-columns: 1fr 1fr;

    column-gap: 80px;
    padding: 0 75px;
}

.page_service .descrip {
    width: 100%;
    display: flex;
    row-gap: 22px;
    flex-direction: column;
    padding: 0 10px;
}

.page_service .descrip h2 {
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-shadow: 0 0 1px #000000d0;
}

.page_service .descrip p {
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 18px;
    text-shadow: 0 0 1px #000000e0;
}

.page_service .descrip .btn_request {
    height: 35px;

    margin-top: 12px;
    padding: 8px 15px;
    color: #0467ABb0;

    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;

    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 2px #80808088;
    transition: all .25s ease-out;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

/* ------------------------------------------------------------------------------- */

.page_service .contact {
    width: 100%;
    max-width: 1440px;

    padding: 0 100px;
    margin: 80px auto 140px;

    display: flex;
    justify-content: space-between;
}

.page_service .contact p {
    width: 100%;
    max-width: 520px;

    display: flex;
    column-gap: 40px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #808080;
}

.page_service .contact p img {
    width: 50px;
    object-fit: contain;
}

.page_service .contact .container_btn_contact {
    display: flex;
    column-gap: 50px;
}

.page_service .contact button,
.page_service .contact a {
    height: 55px;
    padding: 0 50px;
    border-radius: 50px;

    display: flex;
    column-gap: 15px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.page_service .contact button img,
.page_service .contact a img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.page_service .contact .btn-phone {
    box-shadow: 0 0 6px #0467ab66;
    background-color: white;
    color: #0467abb3;
}

.page_service .contact .btn-phone img {
    filter: invert(60%) sepia(98%) saturate(932%) hue-rotate(176deg) brightness(74%) contrast(102%);
}

.page_service .contact .btn-phone:hover {
    background-color: #0467aba3;
    color: #ffffffd0;
}

.page_service .contact .btn-phone:hover img {
    filter: invert(0%) sepia(100%) saturate(2%) hue-rotate(122deg) brightness(153%) contrast(151%);
}

.page_service .contact .btn-email {
    background-color: #0467ab99;
    color: white;
}

.page_service .contact .btn-email:hover {
    box-shadow: 0 0 6px #0467ab66;
    background-color: white;
    color: #0467abb3;
}

.page_service .contact .btn-email:hover img {
    filter: invert(28%) sepia(98%) saturate(932%) hue-rotate(176deg) brightness(74%) contrast(102%);
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_service .container_slider_descrip {
        padding: 40px 0 60px;
    }
    
    .page_service .container_slider_descrip .content {
        display: flex;
        row-gap: 40px;
        flex-direction: column-reverse;
        padding: 0 50px;
    }

    /* ------------------------------------------------------------------------------- */
    
    .page_service .contact {
        margin: 75px auto 100px;
        flex-direction: column;
    }
    
    .page_service .contact p {
        width: unset;
        font-size: 18px;
        column-gap: 20px;
        margin: 0 auto 30px;
    }
    
    .page_service .contact p img {
        width: 45px;
        margin-left: 30px;
        object-fit: contain;
    }
    
    .page_service .contact .container_btn_contact {
        display: flex;
        column-gap: 50px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_service .container_slider_descrip {
        padding: 30px 0 40px;
    }
    
    .page_service .container_slider_descrip .content {
        display: flex;
        row-gap: 40px;
        flex-direction: column-reverse;
        padding: 0 20px;
    }

    .page_service .descrip {
        padding: 0;
    }

    .page_service .descrip h2 {
        font-weight: 700;
        font-size: 26px;
    }
    
    .page_service .descrip p {
        font-weight: 800;
        font-size: 18px;
    }

    .page_service .descrip .btn_request {
        margin: 12px auto 0;
    }

    /* ------------------------------------------------------------------------------- */

    .page_service .contact {    
        row-gap: 35px;
        flex-direction: column;
        
        padding: 0 20px;
        margin: 70px auto 90px;
    }
    
    .page_service .contact p {
        column-gap: 20px;
        font-size: 16px;
    }
    
    .page_service .contact p img {
        width: unset;
        height: 100%;
        margin: 0 10px;
    }
    
    .page_service .contact .container_btn_contact {
        flex-direction: column;
        row-gap: 25px;
    }
    
    .page_service .contact button,
    .page_service .contact a {
        width: 100%;
        
        justify-content: center;
        column-gap: 10px;

        margin: 0 auto;
        padding: 0;
    }
    
    .page_service .contact button img,
    .page_service .contact a img {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }
}