nav .subMenu_mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    direction: rtl;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;
    
    border-bottom: 1px solid #80808020;
    border-top: 1px solid #80808020;
    
    background-color: #80808010;
    padding: 8px 0;
}

nav .subMenu_mobile button {
    width: 100%;
    direction: ltr;
    color: #808080;
    
    display: flex;
    column-gap: 5px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;

    padding: 8px 5px 8px 20px;
    white-space: unset;
    text-align: start;
}

nav .subMenu_mobile button::before {
    content: "- ";
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (min-width: 1200px) {
    nav .subMenu_mobile {
        display: none;
    }
}