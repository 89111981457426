.menu {
    width: 100%;
    height: calc(100vh - 80px);

    background: linear-gradient(90deg, #808080 -55%, #ffffff 10%, #ffffff 90%, #808080 155%);
    animation: open_menu .25s 0s linear forwards;

    transform: translateX(-50%);
    position: fixed;
    z-index: 99;

    top: calc(80px - 100vh);
    left: 50%;
}

@keyframes open_menu {
    0% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(-50%, 100vh);
    }
}

.menu .content {
    width: 100%;
    height: calc(100vh - 80px);
    max-width: 1440px;

    display: flex;
    column-gap: 12px;
    
    padding: 75px 0 40px;
    margin: 0 auto;
}

.menu .content h3 {
    width: 100%;
    max-width: 1440px;
    
    color: #0467ABcc;
    padding: 0 40px 4px;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;

    transform: translateX(-50%);
    position: absolute;

    top: 30px;
    left: 50%;
}

.border {
    width: 1px;
    height: 100%;
    display: flex;
    background-color: #80808050;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format pc --------------------------- */

@media screen and (min-width: 1200px) {

    header .btn_user_menu:hover,
    header .controle_panel .actif,
    nav .btn_nav:hover,
    nav .actif {
        filter: invert(21%) sepia(97%) saturate(6256%) hue-rotate(353deg) brightness(84%) contrast(98%);
        opacity: .7;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    
}