.mdl_verif_pw {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0467AB40;
    padding-bottom: 150px;

    position: fixed;
    z-index: 999;

    left: 0;
    top: 0;
}

.mdl_verif_pw .content {
    width: 750px;

    display: flex;
    align-items: center;
    flex-direction: column;

    row-gap: 20px;
    position: relative;
    padding: 32px 50px 40px;

    box-shadow: 0 0 8px #00000025;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.mdl_verif_pw .content .btn_close {
    color: #444444a0;
    font-size: 20px;
    
    cursor: pointer;
    transition: all .25s;

    position: absolute;
    right: 25px;
    top: 15px;
}

.mdl_verif_pw .content .btn_close:hover {
    color: #D41217b0;
}

.mdl_verif_pw .content span {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 30px;

    color: #0467AB;
    opacity: .8;
}

.mdl_verif_pw .content p {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #444;
    opacity: .8;
}

.mdl_verif_pw .content input {
    height: 38px;
    letter-spacing: 3px;

    color: #444444;
    background-color: white;
    
    border-radius: 3px;
    box-shadow: 0 0 6px #0000001a;
    
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
    
    margin: 10px 0 15px;
    padding: 12px 16px;
}

.mdl_verif_pw .content input::placeholder {
    letter-spacing: 3px;
}

.mdl_verif_pw .content .btn_conf_updt {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;

    border: solid 1px #D41217b0;
    border-radius: 3px;
    color: #D41217b0;
    
    transition: all .25s;
    padding: 8px 22px;
}

.mdl_verif_pw .content .btn_conf_updt:hover {
    background-color: #D41217f0;
    border: solid 1px #D41217;
    color: #fff;
    opacity: .6;
}

.mdl_verif_pw .content .msgConf {
    font-size: 22px;
    font-weight: 600;
    text-align: center;

    color: #D41217f0;
    padding-top: 15px;
}


.mdl_verif_pw .input_code {
    width: 280px;
    height: 36px;

    display: flex;
    column-gap: 10px;

    border-radius: 8px;
    position: relative;
    margin: 25px 0;
}

.mdl_verif_pw .input_code .box {
    width: 25px;
    height: 100%;

    display: flex;
    border-radius: 8px;

    box-shadow: 0 0 8px #80808020;
    border: solid 1px #80808080;
    background-color: white;
}

.mdl_verif_pw .input_code .actif {
    box-shadow: 0 0 8px #0467AB20;
    border: solid 1px #0467AB80;    
}

.mdl_verif_pw .input_code input {
    width: 125%;
    margin: unset;
    padding: 5px 8px;
    border-radius: 8px;
    letter-spacing: 24px;

    box-shadow: unset;
    background-color: transparent;

    font-family: 'Fira Code', sans-serif;
    font-weight: 700;
    font-size: 18px;

    direction: rtl;
    text-align: left;
    caret-color: transparent;

    position: absolute;
    left: 0;
    top: 0;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) { 
    .mdl_verif_pw {
        padding-bottom: 100px;
    }

    .mdl_verif_pw .content {
        width: calc(100% - 40px);
        max-width: 700px;
        
        height: unset;
        max-height: calc(100% - 40px);

        row-gap: 15px;
        padding: 28px 20px 30px;
    }

    .mdl_verif_pw .content span {
        font-size: 24px;
    }
    
    .mdl_verif_pw .content p {
        text-align: center;
        font-weight: 500;
        font-size: 17px;
    }
    
    .mdl_verif_pw .content .msgConf {
        padding-top: 0;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 750px) {
    .mdl_verif_pw {
        padding-bottom: 0;
    }

    .mdl_verif_pw .content {
        height: unset;
        width: calc(100% - 40px);
        max-height: calc(100% - 40px);

        row-gap: 10px;
        padding: 28px 20px 30px;
    }

    .mdl_verif_pw .content p {
        font-family: 'Manrope', sans-serif;
        font-weight: 400;
        font-size: 15px;
        color: #444;
        opacity: .8;
    }
    
    .mdl_verif_pw .content .msgConf {
        font-size: 19px;
    }
}