.page_privacy_policy {
    width: 100%;
    max-width: 1440px;
    min-height: calc(100vh - 375px);

    display: flex;
    row-gap: 50px;
    flex-direction: column;

    padding: 80px 120px 150px;
    margin: 0 auto;
}

.page_privacy_policy h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #444;
}

.page_privacy_policy ul {
    display: flex;
    row-gap: 50px;
    flex-direction: column;    
}

.page_privacy_policy li {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    color: #444;    
}

.page_privacy_policy h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 23px;
}

.page_privacy_policy p {
    line-height: 1.85rem;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.page_privacy_policy span {
    letter-spacing: .5px;
    line-height: 1.5rem;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_privacy_policy {
        margin: 0 auto;
        padding: 80px 50px 90px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_privacy_policy {
        row-gap: 40px;
        margin: 0 auto;
        padding: 40px 25px 90px;
    }

    .page_privacy_policy h2 {
        font-size: 28px;
    }
}