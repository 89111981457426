.menu_list_desktop {
    width: calc(33% - 12px);
    height: 100%;
    max-height: 100%;

    overflow-y: auto;
    direction: rtl;

    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;
    
    padding-left: 5px;
    position: relative;
}

.menu_list_desktop li {
    width: 100%;
    height: 44px;
    display: flex;
}

.menu_list_desktop li button {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    text-align: end;
    column-gap: 15px;
    padding: 0 10px 0 35px;
    border-radius: 4px;

    color: #808080;
    opacity: .85;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.menu_list_desktop li .focus,
.menu_list_desktop li button:hover {
    background-color: #0467ab1a;
}

.menu_list_desktop li img {
    transform: rotate(-90deg);
    filter: invert(46%) sepia(29%) saturate(0%) hue-rotate(167deg) brightness(123%) contrast(96%);

    height: 60%;
    opacity: 0;
}

.menu_list_desktop li .focus img,
.menu_list_desktop li button:hover img {
    opacity: 1;
}