.page_general_conditions_of_use {
    width: 100%;
    max-width: 1440px;
    min-height: calc(100vh - 375px);

    display: flex;
    row-gap: 50px;
    flex-direction: column;

    padding: 80px 120px 150px;
    margin: 0 auto;
}

.page_general_conditions_of_use h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #444;
}

.page_general_conditions_of_use ul {
    display: flex;
    row-gap: 50px;
    flex-direction: column;    
}

.page_general_conditions_of_use li {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    color: #444;    
}

.page_general_conditions_of_use h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 23px;
}

.page_general_conditions_of_use p {
    line-height: 1.75rem;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_general_conditions_of_use {
        margin: 0 auto;
        padding: 80px 50px 90px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_general_conditions_of_use {
        row-gap: 40px;
        margin: 0 auto;
        padding: 40px 25px 90px;
    }

    .page_general_conditions_of_use h2 {
        font-size: 28px;
    }
}