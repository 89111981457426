nav {
    display: flex;
    column-gap: 40px;
    justify-content: center;

    transform: translateX(-50%);
    position: fixed;

    z-index: 101;

    top: 30px;
    left: 50%;
}

nav button {
    white-space: nowrap;
}

nav .actif img {
    transform: rotate(180deg);
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    nav {
        width: 260px;
        height: calc(100vh - 80px);

        box-shadow: 0 8px 8px #00000026;
        background-color: white;
        padding-top: 5px;

        flex-direction: column;
        justify-content: flex-start;

        overflow-y: auto;
        transform: none;
        z-index: 99;

        top: 80px;
        left: unset;
        right: -260px;

        animation: open_menu_mobile .25s 0s ease-out forwards;
    }

    @keyframes open_menu_mobile {
        0% {
            transform: translateX(0px);
        }
        100% {
            transform: translateX(-260px);
        }
    }

    nav .btn_nav {
        width: 100%;
        height: 50px;
        min-height: 50px;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;

        padding: 0 15px 0 41px;
        column-gap: 13px;

        position: relative;
        color: #808080;

        font-size: 14px;
        font-weight: 700;
        font-family: 'Manrope', sans-serif;
    }

    nav .btn_nav img {
        width: 16px;
        height: 16px;
        opacity: .7;

        position: absolute;
        left: 12px;
    }

    nav .btn_open_menu_mobile img {
        width: 20px;
        height: 20px;
        left: 11px;
    }

    nav .btn_user_mobile img {
        width: 19px;
        height: 19px;
        left: 11px;
        filter: invert(55%) sepia(0%) saturate(1066%) hue-rotate(275deg) brightness(52%) contrast(86%);
    }

    nav .actif {
        background-color: #d412171a;
        filter: invert(21%) sepia(97%) saturate(6256%) hue-rotate(353deg) brightness(140%) contrast(105%);
    }

    nav .btn_login_mobile {
        width: 100%;
        height: 50px;
        min-height: 50px;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;

        padding: 0 15px 0 41px;
        column-gap: 13px;

        position: relative;
        color: #808080;

        font-size: 14px;
        font-weight: 600;
        font-family: 'Manrope', sans-serif;
    }
    
    nav .btn_login_mobile img {
        width: 16px;
        height: 16px;
        opacity: .7;

        position: absolute;
        top: 18px;
        left: 14px;
    }
}