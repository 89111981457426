.sect_table_ref_mobile {
    position: relative;
}

.sect_table_ref_mobile .table_ref {
    display: flex;
    row-gap: 15px;
    padding: 25px 0;
    flex-direction: column;
}

.sect_table_ref_mobile .table_ref .table_row {
    box-shadow: 0 0 1px #80808045;
    border: solid 1px #80808010;
    background-color: #0467AB10;
    border-radius: 4px;
    padding: 15px;
}

.sect_table_ref_mobile .table_ref .row {
    display: flex;
    align-items: center;
    border-bottom: #80808040 solid 1px;
    padding: 15px 0;
}

.sect_table_ref_mobile .table_ref .row:first-child {
    padding: 5px 0 15px;
}

.sect_table_ref_mobile .table_ref .row:first-child .value,
.sect_table_ref_mobile .table_ref .row .btn_download {
    font-weight: 800;
    color: #0467AB;
    opacity: .85;
}

.sect_table_ref_mobile .table_ref .row .btn_download {
    display: flex;
    column-gap: 6px;
    align-items: center;
}

.sect_table_ref_mobile .table_ref .row .btn_download img {
    width: unset;
    height: 18px;
    margin-bottom: 2px;
}

.sect_table_ref_mobile .table_ref .row:last-child,
.sect_table_ref_mobile .table_ref .row_last {
    padding: 15px 0 5px;
    border: unset;
}

.sect_table_ref_mobile .table_ref .row p,
.sect_table_ref_mobile .table_ref .row select,
.sect_table_ref_mobile .table_ref .row input {
    width: 50%;
}

.sect_table_ref_mobile .table_ref .row p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 15px;
    max-width: 50%;
}

.sect_table_ref_mobile .table_ref .row .label {
    color: #444444d1;
}

.sect_table_ref_mobile .table_ref .row .value {
    color: #444444;
}

.sect_table_ref_mobile .table_ref .row select,
.sect_table_ref_mobile .table_ref .row input {
    height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    background-color: white;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #808080;
}

.sect_table_ref_mobile .table_ref .btn_go_to_login {
    width: 50%;
    cursor: unset;
    text-align: start;
}

.sect_table_ref_mobile .table_ref .btn_go_to_login p {
    display: flex;
    column-gap: 5px;
    align-items: center;

    font-size: 16px;
    cursor: pointer;
    color: #444;
    opacity: .8;
}

.sect_table_ref_mobile .table_ref .btn_go_to_login i {
    font-size: 17px;
}

.sect_table_ref_mobile .table_ref .btn_add_to_cart,
.sect_table_ref_mobile .table_ref .btn_request {
    width: 100%;
    height: 40px;

    padding: 0 15px;
    margin-top: 15px;
    border-radius: 26px;

    display: flex;
    column-gap: 10px;
    justify-content: center;

    align-items: center;
    align-self: flex-start;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;

    white-space: nowrap;
    margin-right: 5px;

    box-shadow: 0 0 6px #00000015;
    background-color: #0467AB;
    color: #fff;
}

.sect_table_ref_mobile .table_ref button:hover {
    opacity: .7;
    background-color: #0467ab10;
    box-shadow: 0 0 8px #00000045;
}

.sect_table_ref_mobile .table_ref .btn_request img {
    height: 20px;
    width: 25px;
}

.sect_table_ref_mobile .table_ref .btn_add_to_cart img {
    height: 22px;
    width: 25px;
}

/* ------------------------------------------------------------------------------- */

.sect_table_ref_mobile .pagination {
    display: flex;
    column-gap: 5px;
    align-items: flex-end;
    justify-content: center;
}

.sect_table_ref_mobile .pagination button {
    width: 25px;
    height: 25px;
    margin: 15px 0;
    
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;

    border-radius: 15px;
    color: #444;
    opacity: .7;
}

.sect_table_ref_mobile .pagination .actif {
    background-color: #0467AB;
    color: #fff;
    opacity: .65;
}

.sect_table_ref_mobile .pagination span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;

    padding-bottom: 2px;
    color: #444;
    opacity: .8;
}

/* ------------------------------------------------------------------------------- */

.sect_table_ref_mobile .msg_conf {
    width: calc(100% - 40px);

    display: flex;
    row-gap: 6px;
    justify-content: center;
    flex-direction: column;

    box-shadow: 0 0 4px #80808035;
    border: solid 4px #0467AB77;
    border-radius: 15px 15px 0 0;

    background-color: #fff;
    padding: 12px 20px 18px;
    overflow: hidden;

    position: fixed;
    z-index: 999;

    left: 20px;
    bottom: 30px;

    transform: translateY(300px);
    
    animation: msgConfMob 2.5s 0s 1 ease-in-out;
}

@keyframes msgConfMob {
    0% {
        transform: translateY(300px);        
    }
    20% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(300px);        
    }
}

.sect_table_ref_mobile .msg_conf h4 {
    display: flex;
    column-gap: 5px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 20px;

    color: #0467ABc1;
}

.sect_table_ref_mobile .msg_conf h4 img {
    height: 20px;
    opacity: .8;
    margin-bottom: 2px;
}

.sect_table_ref_mobile .msg_conf p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;

    line-height: 1.3em;
    max-width: 80%;
    color: #444;
}

.sect_table_ref_mobile .msg_conf span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #808080;
}

.sect_table_ref_mobile .msg_conf .back_icon {
    height: 84%;
    opacity: .4;

    transform: translateY(-50%);
    position: absolute;
    right: 14px;
    top: 50%;
}

.sect_table_ref_mobile .msg_error {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0467AB25;
    padding-bottom: 150px;

    position: fixed;
    z-index: 999;

    left: 0;
    top: 0;
}

.sect_table_ref_mobile .msg_error .content {
    width: 750px;

    display: flex;
    align-items: center;
    flex-direction: column;

    row-gap: 20px;
    padding: 32px 50px 40px;

    box-shadow: 0 0 8px #00000025;
    background-color: #f9f9f9;
    border-radius: 8px;    
}

.sect_table_ref_mobile .msg_error p {
    display: flex;
    column-gap: 4px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 24px;

    text-align: center;
    color: #D41217c0;    
}