.slider_category {
    width: 100%;
    height: 400px;
    row-gap: 35px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    box-shadow: 0 0 6px #00000015;
    background-color: white;

    border: solid 1px #00000005;
    border-radius: 5px;

    padding: 35px 40px;
    position: relative;
    overflow: hidden;
}

.slider_category .content {
    width: 100%;
    height: 100%;

    display: flex;
    column-gap: 40px;

    align-items: center;
    justify-content: space-between;

    animation: slider 7s 0s infinite linear;
}

@keyframes slider {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
    }

    19.2%,
    80.8% {
        opacity: 1;
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.slider_category .descrip {
    width: calc(100% - 350px);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slider_category h2 {
    color: #0467abcc;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
}

.slider_category p {
    width: 100%;
    max-width: 500px;

    height: 100%;
    max-height: 165px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;

    margin-bottom: 15px;
    padding-left: 4px;
    color: #444444;

    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.slider_category .img_category {
    width: 300px;
    height: 260px;
    display: flex;
    align-items: center;
}

.slider_category img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.slider_category .img_service {
    width: 400px;
    height: 150%;

    animation: sliderServ 7s 0s infinite ease-in-out;

    transform: translateY(-50%) rotate(12deg);
    position: absolute;

    top: 50%;
    right: -100px;

    overflow: hidden;
}

@keyframes sliderServ {
    0% {
        width: 0;
        opacity: 0;
    }

    10%,
    90% {
        width: 400px;
        opacity: 1;
    }

    100% {
        width: 0;
        opacity: 0;
    }
}

.slider_category .img_service div {
    width: 100%;
    height: 100%;

    transform: rotate(-12deg);
    position: absolute;

    right: 100px;
    top: 0;

}

.slider_category .img_service img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider_category .btn_show_more {
    height: 34px;

    padding: 8px 20px;
    color: #0467AB;
    transition: all .2s;

    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;

    border-radius: 3px;
    border: solid 1px #0467AB;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.slider_category .btn_show_more:hover {
    background-color: #0467AB;
    color: white;
    opacity: .7;
}

.slider_category .dots {
    width: 100%;
    height: 10px;

    display: flex;
    column-gap: 11px;
    flex-direction: row;
    justify-content: center;
}

.slider_category .dot {
    width: 8px;
    height: 7px;

    display: flex;
    border-radius: 4px;

    background-color: #80808033;
    box-shadow: 0 0 2px #00000050;
}

.slider_category .dot_actif {
    width: 50px;
}

.slider_category .dot_actif::before {
    width: 8px;
    height: 7px;

    content: '';
    display: flex;

    animation: dotAnime 7s 0s infinite linear;

    background-color: #0467abb0;
    border-radius: 4px;
}

@keyframes dotAnime {
    0% {
        width: 7px;
    }

    100% {
        width: 50px;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format pc_small --------------------------- */

@media screen and (max-width: 1440px) {
    .slider_category {
        padding: 35px 25px;
    }

    .slider_category .content {
        column-gap: 50px;
    }

    .slider_category .img_category {
        width: 260px;
        height: 260px;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .slider_category {
        height: 350px;
        row-gap: 35px;
        padding: 25px 25px;
    }

    .slider_category .content {
        column-gap: 30px;
    }

    .slider_category .descrip {
        width: calc(100% - 330px);
    }

    .slider_category h2 {
        max-width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 22px;
    }

    .slider_category p {
        max-height: 136px;
        -webkit-line-clamp: 8;
        font-size: 14px;
    }

    .slider_category .img_category {
        width: 260px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .slider_category {
        height: unset;

        row-gap: 30px;
        padding: 30px 20px 35px;

        flex-direction: column-reverse;
        background-color: transparent;
        box-shadow: unset;
        border-radius: 0;
        border: unset;
    }

    .slider_category .content {
        flex-direction: column-reverse;
        justify-content: unset;
        row-gap: 25px;
    }

    .slider_category .descrip {
        width: 100%;
        row-gap: 15px;
    }

    .slider_category h2 {
        width: 100%;
        max-width: unset;
    }

    .slider_category p {
        height: 145px;
        max-height: unset;
        font-size: 15px;
    }

    .slider_category .btn_show_more {
        align-self: flex-end;
    }

    .slider_category .img_category {
        width: 100%;
        height: 240px;
    }
}

/* ---------------------------------------------------------------------- */



/* ---------------------------------------------------------------------- */

.slider_product {
    width: 100%;
    height: 400px;

    box-shadow: 0 0 6px #00000015;
    background-color: white;

    border: solid 1px #00000005;
    border-radius: 5px;
}

.slider_product .content {
    width: 100%;
    height: 100%;

    padding: 35px 40px;
    column-gap: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider_product .descrip {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slider_product h2 {
    color: #0467abcc;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
}

.slider_product p {
    width: 100%;
    height: 100%;
    max-height: 215px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 12;

    margin-bottom: 5px;

    color: #444444;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.slider_product .btn_show_more,
.slider_product .btn_request_spe {
    height: 34px;

    padding: 9px 20px;
    color: #0467AB;
    transition: all .2s;

    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;

    border-radius: 3px;
    border: solid 1px #0467AB;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.slider_product .btn_show_more:hover,
.slider_product .btn_request_spe:hover {
    background-color: #0467AB;
    color: white;
    opacity: .7;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .slider_product .content {
        column-gap: 35px;
        padding: 30px 40px;
        flex-direction: row-reverse;
    }

    .slider_product h2 {
        font-size: 24px;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 767px) {
    .slider_product {
        height: unset;
        flex-direction: column-reverse;
        background-color: transparent;
        box-shadow: unset;
        border-radius: 0;
        border: unset;
    }

    .slider_product .content {
        row-gap: 30px;
        padding: 30px 20px 35px;
        flex-direction: column-reverse;
    }

    .slider_product .descrip {
        height: unset;
        row-gap: 15px;
    }

    .slider_product .descrip h2 {
        font-size: 22px;
    }

    .slider_product .descrip p {
        margin-bottom: unset;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    .slider_product .btn_show_more {
        background-color: #0467AB;
        color: white;
        opacity: .85;

        align-self: flex-end;
        margin-top: unset;
    }
}