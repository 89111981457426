.main_menu_home {
    width: 100%;
    max-width: 1290px;

    gap: 20px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.main_menu_home button {
    width: 100%;
    cursor: pointer;

    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.main_menu_home .preview {
    width: 100%;
    height: 150px;
    transition: .25s;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #0467ab4d;
    box-shadow: 0 0 6px #00000025;
}

.main_menu_home .preview:hover {
    background-color: #0467ab3d;
}

.main_menu_home img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.main_menu_home span {
    display: flex;
    text-align: left;
    align-items: flex-start;

    color: #1e1e1e;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .main_menu_home {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .main_menu_home {
        gap: unset;
        row-gap: 20px;
        grid-template-columns: 1fr;
        padding: 44px 22px 70px;
    }

    .main_menu_home img {
        width: 120px;
        height: 100px;
        object-fit: contain;
    }
}


/* ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------ */

.main_menu_category_prod {
    width: 100%;
    max-width: 1290px;

    row-gap: 20px;
    column-gap: 30px;

    display: grid;
    padding: 25px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.main_menu_category_prod button {
    width: 100%;
    height: 100%;
    
    cursor: pointer;
    transition: .25s;

    row-gap: 8px;
    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    box-shadow: 0 0 6px #00000015;
    border-radius: 4px;
    padding: 8px;
}

.main_menu_category_prod button:hover {
    opacity: .6;
}

.main_menu_category_prod .preview {
    width: 100%;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #0467ab40;
}

.main_menu_category_prod img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.main_menu_category_prod span {
    width: 100%;

    display: flex;
    text-align: left;
    align-items: flex-start;

    text-shadow: 0 0 2px #00000025;
    color: #444444;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {  
    .main_menu_category_prod {
        grid-template-columns: 1fr 1fr;
    }

    .main_menu_category_prod img {
        width: 180px;
        height: 140px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {  
    .main_menu_category_prod {
        grid-template-columns: 1fr;
    }
}

/* -------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------- */

.main_menu_product {
    width: 100%;
    max-width: 1290px;

    row-gap: 20px;
    column-gap: 15px;

    display: grid;
    padding: 15px 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.main_menu_product button {
    width: 100%;
    height: 100%;
    min-height: 240px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    padding: 5px 5px 10px;
    cursor: pointer;
    transition: .25s;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 8px #ffffff50;    
}

.main_menu_product button:hover {
    opacity: .7;    
}

.main_menu_product .preview {
    width: 100%;
    height: 200px;  
}

.main_menu_product img {
    width: 100%;
    height: 100%;
    object-fit: contain;    
}

.main_menu_product span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;   
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {  
    .main_menu_product {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {  
    .main_menu_product {
        width: 100%;
        grid-template-columns: 1fr;
    }

    .main_menu_product .preview {
        width: 100%;
        height: 150px;  
    }
    
    .main_menu_product img {
        width: 100%;
        height: 140px;
        object-fit: contain;    
    }

    .main_menu_product button {
        min-height: unset;
        padding: 10px 5px; 
    }
}

/* -------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------- */



.main_menu_service {
    width: 100%;
    max-width: 1290px;

    row-gap: 20px;
    column-gap: 15px;

    display: grid;
    padding: 15px 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.main_menu_service button {
    width: 100%;
    height: 100%;
    min-height: 240px;
    overflow: hidden;

    display: flex;
    row-gap: 5px;
    flex-direction: column;

    padding: 5px 5px;
    cursor: pointer;
    transition: .25s;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 8px #ffffff50;    
}

.main_menu_service button:hover {
    opacity: .7;    
}

.main_menu_service .preview {
    width: 100%;
    height: 190px;  
}

.main_menu_service img {
    width: 100%;
    height: 100%;
    object-fit: cover;    
}

.main_menu_service span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;   
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {  
    .main_menu_service {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {  
    .main_menu_service {
        width: 100%;
        grid-template-columns: 1fr;
    }

    .main_menu_service button {
        padding: 10px 5px; 
    }
}


/* ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------ */

.main_menu_prod-associated {
    width: 100%;
    max-width: 1290px;

    row-gap: 20px;
    column-gap: 30px;

    display: grid;
    padding: 25px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.main_menu_prod-associated button {
    width: 100%;
    height: 100%;
    
    cursor: pointer;
    transition: .25s;

    row-gap: 8px;
    display: flex;
    flex-direction: column;

    background-color: #0467ab68;
    box-shadow: 0 0 6px #00000015;
    border-radius: 4px;
    padding: 8px;
}

.main_menu_prod-associated button:hover {
    opacity: .8;
}

.main_menu_prod-associated .preview {
    width: 100%;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #ffffff;
}

.main_menu_prod-associated img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.main_menu_prod-associated span {
    width: 100%;

    display: flex;
    text-align: left;
    align-items: flex-start;

    text-shadow: 0 0 2px #00000025;
    color: #ffffff;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {  
    .main_menu_prod-associated {
        grid-template-columns: 1fr 1fr;
        padding: 0 10px;
    }

    .main_menu_prod-associated img {
        width: 180px;
        height: 140px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {  
    .main_menu_prod-associated {
        grid-template-columns: 1fr;
        padding: 20px 0;
    }
}