.page_home {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 65px auto 80px;

    display: flex;
    row-gap: 50px;
    flex-direction: column;
}

.page_home .container_slider_banner {
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format pc_small --------------------------- */

@media screen and (max-width: 1440px) {
    .page_home {
        padding: 0 45px;
        margin: 60px auto;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_home {
        padding: 0 30px;
        margin: 40px auto;
    }
    
    .page_home .container_slider_banner {
        flex-direction: column;
        row-gap: 40px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_home {
        row-gap: 0;
        padding: 0;
        margin: 0;
    }
    
    .page_home .container_slider_banner {
        row-gap: 0;
    }
}