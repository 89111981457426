.btn_home {
    height: 46px;
    display: flex;
    column-gap: 15px;
    transition: .2s;
}

.btn_home:hover {
    opacity: .5;
}

.btn_home img {
    height: 100%;
}

.btn_home h1 {
    height: 100%;
    row-gap: 4px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    color: #0467AB;
}

.btn_home span {
    font-size: 14px;  
    font-weight: 400;
    color: #444;
}