.btn_check {
    display: flex;
    column-gap: 6px;
    align-items: center;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.btn_check p {
    white-space: nowrap;
}

.btn_check .box {
    width: 12px;
    height: 12px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    box-shadow: 0 0 4px #00000015;
    border: 1px solid #ccc;
    
    background-color: #fff;
    color: #0467ab;
    
    margin: 0 4px 2px 0;
    font-weight: 800;
}