.form_login {
    width: 100%;
    max-width: 370px;

    display: flex;
    row-gap: 50px;
    flex-direction: column;
}

.form_login .title_form {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #333;
}

.form_login .container_input {
    display: flex;
    row-gap: 25px;
    flex-direction: column;
}

.form_login .login_options {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form_login .btn_account_forget {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;

    transition: all .25s;
    color: #0467AB;
}

.form_login .btn_account_forget:hover {
    text-decoration: underline;
    opacity: .7;
}

.form_login .msg_error {
    display: flex;
    column-gap: 4px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #D41217;
}

.form_login .btn_submit {
    display: flex;
    align-self: flex-start;
    
    color: #D41217;
    border-radius: 3px;
    border: solid 1px #D41217;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    
    transition: all .2s;
    padding: 8px 28px;
}

.form_login .btn_submit:hover {
    background-color: #D41217;
    color: #fff;
    opacity: .65;    
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .form_login {
        row-gap: 35px;
    }
}