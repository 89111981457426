header {
    width: 100%;
    display: flex;
    justify-content: center;

    user-select: none;
    background-color: white;
    box-shadow: 0 0 8px #00000026;

    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
}

header .content {
    width: 100%;
    height: 80px;

    padding: 0 25px;
    max-width: 1440px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .btn_user_menu,
nav .btn_nav {
    display: flex;
    column-gap: 4px;
    align-items: center;
    padding: 0 0 1px 4px;

    opacity: .9;
    color: #444;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

header .controle_panel .actif {
    border-bottom: 1px solid red;
    padding: 0 0 0 4px;
}

header .actif img,
nav .actif img {
    transform: rotate(180deg);
}

header .controle_panel {
    height: 35px;

    display: flex;
    column-gap: 15px;

    align-items: center;
    justify-content: flex-end;

    position: relative;
}

header .btn_page_login {
    width: 150px;
    height: 35px;

    padding: 8px 0;
    color: #0467AB;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    border: solid 1px #0467AB;
    transition: all .25s ease-out;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

header .btn_page_login:hover {
    background-color: #0467AB;
    color: white;
    opacity: .7;
}

header .f_mobile {
    display: none;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format pc --------------------------- */

@media screen and (min-width: 1200px) {

    header .btn_user_menu:hover,
    header .controle_panel .actif,
    nav .btn_nav:hover,
    nav .actif {
        filter: invert(21%) sepia(97%) saturate(6256%) hue-rotate(353deg) brightness(84%) contrast(98%);
        opacity: .7;
    }
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    header .f_desktop {
        display: none;
    }

    header .f_mobile {
        display: unset;
    }

    header .content {
        padding: 0 50px;
    }

    header .btn_login_mobile,
    header .btn_menu_mobile {
        width: 35px;
        height: 35px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: solid 2px #0467a9a6;
        border-radius: 3px;
        transition: .2s;
    }

    header .btn_selector {
        transform: scale(1.05);
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    header .content {
        padding: 0 25px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 460px) {
    header .content {
        padding: 0 19px;        
    }
}