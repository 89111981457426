.breadcrumb {
    width: 100%;
    max-width: 1390px;

    display: flex;
    align-items: center;

    background-color: #fff;
    box-shadow: 0 0 6px #44444420;
    
    border-radius: 0 0 50px 50px;
    border: solid 1px #80808035;
    border-top: unset;
    
    overflow: hidden;
    margin: 0 auto;

    transform: translateX(-50%);
    position: fixed;

    z-index: 1;
    top: 80px;
    left: 50%;
}

.breadcrumb ul {
    width: 100%;

    display: flex;
    align-items: center;
    
    flex-wrap: wrap;
    gap: 6px;
    
    padding: 15px 75px;
    background-color: #0467ab10;
}

.breadcrumb li {
    user-select: none;
    color: #444;
    opacity: .7;

    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.breadcrumb .home_brcmb {
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 2px #80808020;
    border-radius: 5px 5px 4px 4px;

    background-color: #808080b0;
    margin-top: 1px;

    transition: all .2s;
    cursor: pointer;
}

.breadcrumb .home_brcmb:hover {
    background-color: #0467ab;
    opacity: .4;
}

.breadcrumb .home_brcmb img {
    width: 12px;
    height: 12px;
}

.breadcrumb .btn_breadcrumb {
    display: flex;
    column-gap: 6px;
    color: #44444490;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.breadcrumb .btn_breadcrumb button {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;

    transition: all .2s;
    color: #444;
}

.breadcrumb_reduce .btn_breadcrumb button {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.breadcrumb_ultra_reduce .btn_breadcrumb button {
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.breadcrumb .btn_breadcrumb button:hover {
    color: #0467ab;
    opacity: .7;
}

.breadcrumb .end_point {
    color: #0467ab;
    opacity: .75;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
}