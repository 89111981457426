.user_menu {
    width: 240px;
    height: unset;

    padding: 4px;
    border-radius: 3px;
    background-color: white;
    border: .5px solid #00000010;
    box-shadow: 0 0 4px #00000010;

    transform: translate(-50%);
    position: fixed;
    z-index: 101;

    left: calc(55% + 460px);
    top: 100px;
}

.user_menu::after {
    content: '';
    display: flex;
    
    width: 12px;
    height: 12px;

    border-top: solid .5px #00000010;
    border-left: solid .5px #00000010;

    box-shadow: -1px -1px 1px #00000005;
    background: linear-gradient(-45deg, #ffffff00 0%, #ffffff00 45%, #ffffff 45%, #ffffff 100%);

    transform: rotate(45deg);

    position: absolute;
    right: 20px;
    top: -7px;
}

.user_menu button {
    width: 100%;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;

    padding: 0 15px;
    column-gap: 13px;

    color: #808080;
    transition: all .25s;

    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.user_menu button:hover {
    background-color: #0467ab1a;
    opacity: .75;
}