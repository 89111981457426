.page_contact {
    background-color: #0467ab08;
}

.page_contact .content {
    width: 100%;
    max-width: 1440px;

    display: flex;
    column-gap: 100px;

    padding: 65px 100px;
    margin: 0 auto;
}

.page_contact h2 {
    display: flex;
    column-gap: 15px;
    align-items: center;

    color: #444444;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
}

.page_contact h2 img {
    width: 40px;
    height: 40px;
}

.page_contact .location {
    width: 50%;
    display: flex;
    row-gap: 30px;
    flex-direction: column;
}

.page_contact .location .container {
    display: flex;
    row-gap: 20px;
    padding: 20px;
    flex-direction: column;
    
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 6px #00000005;
}

.page_contact .location .address {
    color: #444444;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.page_contact .location .phone {
    color: #444444;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.page_contact .location .phone span {
    font-weight: 400;
}

.page_contact .location .map {
    margin: 0;
    z-index: 1;
    position: relative;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_contact .content {
        row-gap: 40px;
        column-gap: 40px;
        margin: 0 auto;
        padding: 40px 25px 60px;
    }

    .page_contact h2 {
        font-size: 28px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_contact .content {
        flex-direction: column;
        row-gap: 70px;
    }

    .page_contact .location {
        width: 100%;
    }
}

/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */

.form_contact {
    width: 50%;
    display: flex;
    row-gap: 30px;
    flex-direction: column;
}

.form_contact .container {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
}

.form_contact label {
    color: #444444;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.form_contact .input_request {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.form_contact .input_request textarea {
    height: 133px;
    padding: 10px 16px;

    color: #444444;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 6px #00000005;
    
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;    
}

.form_contact .input_file {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.form_contact .input_file .inputFile {
    width: 100%;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background: #fff;
    border: dashed 2px #44444450;
    border-radius: 3px;
    position: relative;
}

.form_contact .inputFile input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
}

.form_contact .inputFile p {
    display: flex;
    align-items: center;
    justify-content: center;

    column-gap: 10px;
    color: #44444466;

    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 18px;    
}

.form_contact .inputFile img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.form_contact button {
    display: flex;
    align-self: flex-start;

    color: #0467AB;
    padding: 8px 28px;
    background-color: #f5f9ff;

    border-radius: 3px;
    transition: all .25s;
    border: solid 1px #0467AB;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.form_contact button:hover {
    background-color: #0467AB;
    color: white;
    opacity: .7;
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .form_contact {
        width: 100%;
    }

    .form_contact button {
        margin: 0 auto;
    }
}