.page_settings {
    width: 100%;
    max-width: 1440px;

    display: flex;
    row-gap: 50px;
    flex-direction: column;

    padding: 80px 75px;
    margin: 0 auto 80px;
}

.page_settings h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #444;
}

.page_settings .form_settings {
    display: flex;
    row-gap: 35px;
    flex-direction: column;
}

.form_settings h3 {
    display: flex;
    column-gap: 7px;
    align-items: center;

    border-bottom: 2px solid #444444b8;
    padding-bottom: 4px;
    opacity: .95;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #444;
}

.form_settings h3 img {
    margin-bottom: 1px;
    opacity: .9;
}

.form_settings .container_inp {
    display: grid;
    align-items: flex-end;

    row-gap: 20px;
    column-gap: 30px;
    
    grid-template-columns: 1fr 1fr;
    padding: 0 15px;
}

.input_settings input {
    box-shadow: 0 0 6px #0000001a;
}

.form_settings .lock input {
    background-color: #E7E7E7;
}

.form_settings .lock input:focus {
    box-shadow: 0 0 5px #0000001a;
    font-weight: 600;
    color: #444;
}

.form_settings .panel {
    display: flex;
    column-gap: 140px;
    justify-content: center;
}

.form_settings .panel button {
    display: flex;
    transition: all .25s;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;

    color: #D41217;
    border-radius: 3px;
    border: solid 1px #D41217;

    padding: 7px 16px;
    opacity: .75;
}

.form_settings .panel button:hover {
    background-color: #D41217;
    color: #fff;
    opacity: .6;
}

.form_settings .panel .btn_blue {
    opacity: .8;
    color: #0467AB;
    border: solid 1px #0467AB;    
}

.form_settings .panel .btn_blue:hover {
    background-color: #0467AB;
}

.form_settings .btn_edit {
    height: 40px;
    max-width: 280px;

    transition: all .2s;
    padding: 10px 16px;
    margin-bottom: 1px;

    color: #0467AB;
    background-color: #fff;
    box-shadow: 0 0 6px #0000001a;

    opacity: .8;
    border-radius: 3px;
    border: solid 1px #0467ABb0;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.form_settings .btn_edit:hover {
    background-color: #0467AB;
    color: #fff;
    opacity: .5;
}

.form_settings .msg_conf {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0467AB25;
    padding-bottom: 150px;

    position: fixed;
    z-index: 999;

    left: 0;
    top: 0;
}

.form_settings .msg_conf .content {
    width: 750px;

    display: flex;
    align-items: center;
    flex-direction: column;

    row-gap: 20px;
    padding: 32px 50px 40px;

    box-shadow: 0 0 8px #00000025;
    background-color: #f9f9f9;
    border-radius: 8px;    
}

.form_settings .msg_conf p {
    display: flex;
    column-gap: 4px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 24px;

    text-align: center;
    color: #D41217c0;    
}

/* ------------------------------------------------------------------------------- */

.form_address .address_selector {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    
    padding: 0 15px;
    row-gap: 8px;
}

.form_address .address_selector label {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #444;    
}

.form_address .address_selector select {
    padding: 10px 16px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px #0000001a;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #444;    
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_settings {
        padding: 50px;
        row-gap: 30px;
    }

    .page_settings h2 {
        font-size: 28px;
    }

    .form_settings h3 {
        font-size: 22px;
    }

    .form_address .address_selector {
        padding: 0;
    }

    .page_settings .container_inp {
        padding: 0;
    }
    
    .form_settings .msg_conf {
        padding-top: 100px;
    }
    
    .form_settings .msg_conf p {
        padding: 0;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 750px) {
    .page_settings {
        padding: 50px 20px;
        row-gap: 10px;
    }

    .page_settings h2 {
        font-size: 25px;
    }

    .form_settings h3 {
        font-size: 22px;
        margin-top: 40px;
    }

    .page_settings .form_settings {
        padding-top: 0;
        row-gap: 25px;
    }

    .page_settings .container_inp {
        grid-template-columns: 1fr;
    }

    .page_settings .invisible {
        display: none;
    }

    .form_settings .btn_edit {
        margin: 0 auto;
    }

    .form_settings .msg_conf {
        padding-bottom: 0;
    }
    
    .form_settings .msg_conf .content {
        width: calc(100% - 40px);
        max-width: 700px;
        
        height: unset;
        max-height: calc(100% - 40px);

        row-gap: 15px;
        padding: 28px 20px 30px;
    }
    
    .form_settings .msg_conf p {
        font-size: 18px;  
    }
}