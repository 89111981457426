.page_cart {
    width: 100%;
    max-width: 1440px;

    display: flex;
    row-gap: 50px;
    flex-direction: column;

    padding: 80px 75px;
    margin: 0 auto 80px;
}

.page_cart h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #444;
}

.page_cart .container_board_table {
    display: flex;
    column-gap: 50px;
}

.page_cart .board {
    width: 340px;
    min-width: 340px;
    display: flex;
    row-gap: 25px;
    flex-direction: column;
}

.page_cart .board .content,
.page_cart .board .contact {
    width: 100%;
    padding: 50px 40px;
    background-color: #fff;
    box-shadow: 0 0 4px #00000025;
}

.page_cart .board .content img,
.page_cart .board .contact img {
    width: 44px;
    height: 44px;
    display: flex;
    margin: 0 auto 50px;
}

.page_cart .board .content .total {
    width: 100%;
    row-gap: 5px;
    display: flex;
    flex-direction: column;
}

.page_cart .board .content .total li {
    padding: 3px 0;
}

.page_cart .board .content .total .total_ttc {
    margin-top: 3px;
}

.page_cart .board .content .total h4,
.page_cart .board .content .total span {
    font-weight: 400;
    font-size: 14px;
}

.page_cart .board .content .total .total_ttc h4,
.page_cart .board .content .total .total_ttc span {
    font-weight: 400;
    font-size: 16px;
}

.page_cart .board .content .total span {
    padding: 3px 15px;
}

.page_cart .board .content .total .total_ttc span {
    border-radius: 50px;
    background-color: #f9f9f9;
    box-shadow: 0 0 4px #00000025;
}

.page_cart .board .content .list {
    width: 100%;
    row-gap: 8px;
    display: flex;
    flex-direction: column;

    border-top: solid 1px #44444417;
    border-bottom: solid 1px #44444417;

    padding: 30px 2px;
    margin: 30px 0;
}

.page_cart .board .content li {
    display: flex;
    justify-content: space-between;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #444;
}

.page_cart .board .content .void {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page_cart .board .content .list span {
    font-size: 14px;    
}

.page_cart .board .content .btn_ask_quote {
    width: 100%;
    padding: 10px 0;
    border-radius: 3px;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.page_cart .board .content .btn_inactif {
    background-color: #444444;
    opacity: .4;
}

.page_cart .board .content .btn_actif {
    background-color: #0467AB;
    transition: all .2s;
}

.page_cart .board .content .btn_actif:hover {
    opacity: .7;
}

.page_cart .board .contact p {
    line-height: 1.5em;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #444;    
}

/* ------------------------------------------------------------------------------- */

.page_cart .table {
    width: 100%;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
}

.page_cart .table li {
    width: 100%;
    height: 70px;

    display: flex;
    column-gap: 5px;
    padding: 5px;

    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 4px #00000025;
}

.page_cart .table .inUpdate {
    background-color: #fff7d9;
}

.page_cart .table li .container_img {
    width: 60px;
    min-width: 60px;
    border-radius: 3px;
    background-color: #0467AB37;
}

.page_cart .table li .container_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.page_cart .table li .descrip {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.page_cart .table li .identification {
    width: 100%;
    max-width: 35%;
    display: flex;
    column-gap: 15px;
    align-items: flex-start;
    justify-content: space-between;
}

.page_cart .table li .identification h3 {
    display: flex;
    flex-direction: column;
    
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}

.page_cart .table li .identification h3 p {
    color: #0467AB;    
    font-size: 14px;
}

.page_cart .table li .price_qte {
    width: 100%;
    max-width: 75%;

    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: flex-end;
}

.page_cart .table li .price_qte p {
    display: flex;
    column-gap: 8px;
    align-items: center;
    
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #444;
}

.page_cart .table li .price_qte p i {
    font-style: normal;
    column-gap: 2px;
    display: flex;
}

.page_cart .table li .price_qte p span {
    font-style: normal;
    font-size: 10px;
    margin-top: 2px;
}

.page_cart .table li .price_qte p .price {
    font-weight: 700;
    font-size: 14px;
    margin-top: 0;
    color: #444;
}

.page_cart .table li .price_qte p input {
    max-width: 55px;
    display: flex;

    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 4px #00000025;
    
    padding: 6px 2px 6px 6px;
    
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #444;
}

.page_cart .table .panel_edit {
    justify-content: center;    
    column-gap: 55px;
    padding: 15px 0;
    
    background-color: unset;
    border-radius: unset;
    box-shadow: unset;
    height: unset;
}

.page_cart .table .panel_edit button {
    display: flex;
    transition: all .25s;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;

    border-radius: 3px;
    padding: 5px 16px;
}

.page_cart .table .panel_edit .btn_cancel {
    border: solid 1px #D41217;
    color: #D41217;
    opacity: .75;
}

.page_cart .table .panel_edit .btn_cancel:hover {
    background-color: #D41217;
    color: #fff;
    opacity: .6;
}

.page_cart .table .panel_edit .btn_edit {
    border: solid 1px #0467AB;  
    color: #0467AB;
    opacity: .8;
}

.page_cart .table .panel_edit .btn_edit:hover {
    background-color: #0467AB;
    color: #fff;
    opacity: .7;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_cart {
        padding: 50px;
        row-gap: 30px;
    }

    .page_cart h2 {
        font-size: 28px;
    }

    .page_cart .table {
        display: grid;
        column-gap: 15px;
        align-self: flex-start;
        grid-template-columns: 1fr 1fr;
    }

    .page_cart .table li {
        height: unset;
        max-width: 100%;
        min-height: 125px;
    }

    .page_cart .table li .descrip {
        max-height: unset;
        flex-direction: column;
        row-gap: 15px;
        padding: 0 5px 2px;
    }

    .page_cart .table li .identification {
        max-width: unset;
    }

    .page_cart .table li .price_qte {
        max-width: unset;
        flex-wrap: wrap;
        row-gap: 6px;
        justify-content: space-between;
    }

    .page_cart .table li .price_qte p:first-child {
        width: 100%;
    }

    .page_cart .table li .price_qte p input {
        padding: 3px 2px 3px 6px;
    }
}

@media screen and (max-width: 1050px) {
    .page_cart .container_board_table {
        display: flex;
        column-gap: 50px;
    }

    .page_cart .table {
        grid-template-columns: 1fr;
    }

    .page_cart .table li .price_qte {
        row-gap: 0;
    }
}

@media screen and (max-width: 850px) {
    .page_cart .container_board_table {
        column-gap: 25px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_cart {
        padding: 50px 20px;
        row-gap: 25px;
    }

    .page_cart h2 {
        font-size: 28px;
    }

    .page_cart .container_board_table {
        flex-direction: column;
        row-gap: 30px;
    }

    .page_cart .board {
        width: 100%;
        min-width: unset;
    }
}