.page_historical {
    display: flex;
    row-gap: 50px;
    flex-direction: column;

    padding: 80px 0 160px;
    min-height: calc(100vh - 375px);
    background-color: #0467ab08;
}

.page_historical h2 {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 0 auto;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #444;
}

.page_historical .filters {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 0 auto;

    display: flex;
    column-gap: 50px;
}

.page_historical .filter {
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.page_historical .filter label {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #444;
}

.page_historical .filter select {
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 5px;
    box-shadow: 0 0 4px #00000015;
    background-color: #fff;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

/* ------------------------------------------------------------------ */

.page_historical section {
    width: 100%;
    max-width: 1440px;

    padding: 0 75px;
    margin: 0 auto;
}

.page_historical ul {
    width: 100%;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
}

.page_historical ul li {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    border-radius: 5px;
    background-color: #fff;
    padding: 16px 50px 16px 20px;
    box-shadow: 0 0 6px #00000015;
}

.page_historical ul .head {
    padding: 0 50px 0 20px;
    background-color: transparent;
    box-shadow: unset;
    border-radius: 0;

}

.page_historical ul .head p {
    font-weight: 600;
}

.page_historical ul p,
.page_historical ul span {
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #444;
}

.page_historical ul .other {
    font-size: 13px;
    color: #444;
    opacity: .7;
}

.page_historical ul .btn_download_quote,
.page_historical ul .btn_repeat_request {
    display: flex;
    cursor: unset;
    justify-content: flex-end;
}

.page_historical ul .btn_download_quote a,
.page_historical ul .btn_repeat_request span {
    display: flex;
    column-gap: 4px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;

    transition: all .25s;
    color: #0467AB;
}

.page_historical ul .btn_repeat_request span {
    align-items: center;
    cursor: pointer;
}

.page_historical ul .btn_download_quote a:hover,
.page_historical ul .btn_repeat_request span:hover {
    opacity: .45;
}

.page_historical ul .btn_download_quote img {
    height: 18px;
}

.page_historical ul .btn_repeat_request img {
    height: 16px;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    .page_historical {
        padding: 50px 0;
        row-gap: 30px;
    }

    .page_historical h2 {
        padding: 0 50px;
        font-size: 28px;
    }

    .page_historical .filters {
        padding: 0 50px;
    }

    .page_historical section {
        padding: 0 50px;
    }
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    .page_historical {
        padding: 50px 0;
        row-gap: 30px;
    }

    .page_historical h2 {
        padding: 0 20px;
        font-size: 28px;
    }

    .page_historical .filters {
        row-gap: 20px;
        padding: 0 20px;
        flex-direction: column;
    }

    .page_historical section {
        padding: 0 20px 40px;
    }

    .page_historical ul li {
        width: 100%;
        row-gap: 5px;
        grid-template-columns: 1fr;
        padding: 10px 15px;
    }

    .page_historical ul li div {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .page_historical ul p,
    .page_historical ul span {
        width: 100%;
        color: #808080;
    }

    .page_historical ul .btn_download_quote a,
    .page_historical ul .btn_repeat_request span {
        width: 100%;
        padding: 14px 0 5px;
        justify-content: center;
    }

    .page_historical ul .label {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: #444;
    }
}