.sect_table_ref {
    overflow: hidden;
    user-select: none;
    border-radius: 10px 10px 0 0;
    padding-bottom: 20px;
}

.sect_table_ref .table_ref {
    display: flex;
    transform: rotateX(180deg);
    column-gap: 18px;
    overflow-x: auto;
    overflow-y: visible;
    padding: 15px 15px 0;
    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;
}

.sect_table_ref .table_ref > * {
    transform: rotateX(180deg);
}

.sect_table_ref .table_ref .table {
    display: flex;
    row-gap: 12px;
    flex-direction: column;
}

.sect_table_ref .table_ref .container_buttons_buy {
    width: 120px;
    min-width: 120px;
    padding: 15px 0 0;
    
    display: flex;
    row-gap: 12px;
    flex-direction: column;
}

.sect_table_ref .table_ref .container_buttons_buy h3 {
    margin-bottom: 4px;
}

.sect_table_ref .table_ref .container_buttons_buy ul {
    width: 100%;
    display: flex;
    row-gap: 12px;
    flex-direction: column;
}

.sect_table_ref .table_ref .container_buttons_buy button {
    width: 110px;
    height: 54px;

    padding: 0 15px;
    border-radius: 26px;

    display: flex;
    column-gap: 10px;

    align-items: center;
    align-self: flex-start;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;

    white-space: nowrap;
    margin-right: 5px;

    box-shadow: 0 0 6px #00000015;
    background-color: #fff;
    color: #0467abb3;
}

.sect_table_ref .table_ref .container_buttons_buy button:hover {
    opacity: .7;
    background-color: #0467ab10;
    box-shadow: 0 0 8px #00000045;
}

.sect_table_ref .table_ref .container_buttons_buy button img {
    height: 30px;
}

.sect_table_ref .table_head {
    display: flex;
    padding: 15px 0 4px;
}

.sect_table_ref .table_ref .container_buttons_buy h3,
.sect_table_ref .table_head h3 {
    width: 157px;
    color: #808080;
    text-align: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.sect_table_ref .table_head h3 {
    font-weight: 800;
    opacity: .95;
}

.sect_table_ref .table_row {
    height: 54px;

    display: flex;
    align-self: flex-start;

    border-radius: 40px;
    box-shadow: 0 0 6px #44444420;
    background-color: #0467AB24;
}

.sect_table_ref .table_row:nth-child(2n+1) {
    box-shadow: 0 0 6px #44444430;
    background-color: #0467AB10;
}

.sect_table_ref .table_row .name_ref {
    width: 157px;
    color: #444;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-start;

    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;

    padding: 3px 0 0 35px;
}

.sect_table_ref .table_row .name_ref span {
    color: #0FC646;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 13px;
}

.sect_table_ref .table_row:nth-child(2n+1) .name_ref span {
    text-shadow: 0 0 2px #44444415;
}

.sect_table_ref .table_row .btn_download {
    width: 157px;
    cursor: unset;
}

.sect_table_ref .table_row .btn_download p {
    width: 100%;
    cursor: pointer;
    color: #0467AB;
    transition: all .2s;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.sect_table_ref .table_row .btn_download:hover {
    text-decoration: underline;
    color: #0467AB88;
}

.sect_table_ref .table_row .filter,
.sect_table_ref .table_row p {
    width: 157px;
    color: #808080;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.sect_table_ref .table_row select,
.sect_table_ref .table_row input {
    width: 77px;
    height: 32px;

    margin: auto 40px;
    padding: 0 0 0 10px;

    border-radius: 3px;
    box-shadow: 0 0 4px #44444425;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;

    text-align: center;
    color: #808080;
}

.sect_table_ref .table_row select {
    width: unset;
}

.sect_table_ref .fa-eye-slash {
    width: 77px;
    height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    color: #444444b0;
    font-size: 18px;
    
    transition: all .2s;
    margin: auto 40px;
    cursor: pointer;
}

.sect_table_ref .fa-eye-slash:hover {
    color: #44444470;
}

.sect_table_ref .fa-eye-slash::after {
    content: 'login/signup';
    position: absolute;
    right: -40px;
    top: 0;

    display: flex;
    padding: 3px 5px 4px;

    background-color: #444;
    border-radius: 3px 4px;
    color: #fff;
    opacity: 0;

    font-family: 'Dosis', sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.sect_table_ref .fa-eye-slash:hover::after {
    animation: tooltips .1s .5s 1 forwards;
}

@keyframes tooltips {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.sect_table_ref .paging {
    display: flex;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.sect_table_ref .paging button {
    height: 34px;
    padding: 0 18px;
    margin: 11px 0 8px;
    
    border-radius: 4px;
    box-shadow: 0 0 4px #00000028;

    color: #444444a0;
    transition: all .2s;
    background-color: #80808035;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.sect_table_ref .paging button:hover {
    background-color: #0467AB80;
    color: #fff;
}

.border_table {
    border: solid 1px #00000015;
    background-color: #ffffff50;
}

/* ------------------------------------------------------------------------------- */

.sect_table_ref .pagination {
    display: flex;
    column-gap: 5px;
    align-items: flex-end;
    justify-content: center;
}

.sect_table_ref .pagination button {
    width: 30px;
    height: 30px;
    
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;

    border-radius: 15px;
    color: #444;
    opacity: .7;
}

.sect_table_ref .pagination .actif {
    background-color: #0467AB;
    color: #fff;
    opacity: .65;
}

.sect_table_ref .pagination span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;

    padding-bottom: 2px;
    color: #444;
    opacity: .8;
}

/* ------------------------------------------------------------------------------- */

.sect_table_ref .msg_conf {
    width: 400px;

    display: flex;
    row-gap: 6px;
    justify-content: center;
    flex-direction: column;

    box-shadow: 0 0 4px #80808035;
    border: solid 4px #0467AB77;
    border-radius: 0 15px 15px 0;

    background-color: #fff;
    padding: 12px 20px 18px;
    overflow: hidden;

    position: fixed;
    z-index: 999;

    left: 40px;
    bottom: 40px;

    transform: translateX(-500px);
    
    animation: msgConf 2.5s 0s 1 ease-in-out;
}

@keyframes msgConf {
    0% {
        transform: translateX(-500px);        
    }
    20% {
        transform: translateX(0);
    }
    80% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-500px);        
    }
}

.sect_table_ref .msg_conf h4 {
    display: flex;
    column-gap: 5px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 20px;

    color: #0467ABc1;
}

.sect_table_ref .msg_conf h4 img {
    height: 20px;
    opacity: .8;
    margin-bottom: 2px;
}

.sect_table_ref .msg_conf p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;

    line-height: 1.3em;
    max-width: 80%;
    color: #444;
}

.sect_table_ref .msg_conf span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #808080;
}

.sect_table_ref .msg_conf .back_icon {
    height: 84%;
    opacity: .4;

    transform: translateY(-50%);
    position: absolute;
    right: 14px;
    top: 50%;
}

.sect_table_ref .msg_error {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0467AB25;
    padding-bottom: 150px;

    position: fixed;
    z-index: 999;

    left: 0;
    top: 0;
}

.sect_table_ref .msg_error .content {
    width: 750px;

    display: flex;
    align-items: center;
    flex-direction: column;

    row-gap: 20px;
    padding: 32px 50px 40px;

    box-shadow: 0 0 8px #00000025;
    background-color: #f9f9f9;
    border-radius: 8px;    
}

.sect_table_ref .msg_error p {
    display: flex;
    column-gap: 4px;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 24px;

    text-align: center;
    color: #D41217c0;    
}