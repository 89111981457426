header .btn_selector {
    width: 57px;
    height: 30px;

    display: flex;
    column-gap: 4px;
    align-items: center;

    background-color: #fff;
    border-radius: 4px;
    padding: 2px 5px;
}

header .btn_selector img {
    height: 100%;
    object-fit: contain;
}

header .btn_selector .chevron {
    width: 17px;
}

header .panel_of_lang {
    width: 57px;

    row-gap: 4px;
    display: flex;

    align-items: center;
    flex-direction: column;

    box-shadow: 0 1px 1px #00000015;
    background-color: #fff;
    
    padding: 2px 5px 5px;
    border-radius: 4px;

    position: absolute;
    left: -75px;
    top: 0;
}

header .panel_of_lang button {
    height: 30px;
}

header .panel_of_lang img {
    height: 100%;
    object-fit: contain;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    header .btn_selector img {
        height: 110%;
    }

    header .panel_of_lang {
        left: -80px;
    }
}